import { Component, OnInit, OnChanges, SimpleChanges, Input, Output, EventEmitter, ViewChild, ChangeDetectorRef, HostListener } from '@angular/core';
import { WeatherService } from '../../services/weather/weather.service';
import { LoggingService } from '../../services/logging/logging.service';
import { StateService } from '../../services/state/state.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { LazyLoadEvent } from 'primeng/api';
import { Table } from 'primeng/table';
import { ObjectUtils } from 'primeng/utils';


// Application Models
import { StationSummaryModel } from '../../models/station-summary.model';
import { MinuteDataModel } from '../../models/minute-data.model';

import { Pagination } from '../../shared/models/pagination.model';

import * as moment from 'moment';
import { Paginator } from 'primeng/paginator';

import { GoogleAnalyticsService } from '../../services/google-analytics/google-analytics.service';

@Component({
  selector: 'app-summary-data-table',
  templateUrl: './summary-data-table.component.html',
  styleUrls: ['./summary-data-table.component.scss']
})
export class SummaryDataTableComponent implements OnInit, OnChanges {

  @ViewChild('dt', { static: true }) dataTable: Table;

  @Input() summaryType: string; // Default
  @Input() active = false;
  @Input() dateRange = null;
  @Output() activeChange = new EventEmitter();

  @Output() tableMetricChange = new EventEmitter();

  @Input() stationCode: string;
  @Input() stationName: string;

  scrollHeight: string =  'calc(100vh - 480px)';

  @HostListener('window:resize', ['$event'])
  resizeHandler(e) {
    if (e.currentTarget !== undefined && e.currentTarget.innerHeight !== undefined) {
      const windowHeight = e.currentTarget.innerHeight;
      switch (windowHeight) {
        case windowHeight < 573:
          this.scrollHeight = 'calc(100vh - 50vh)';
          break;
        case windowHeight < 715:
            this.scrollHeight = 'calc(100vh - 40vh)';
          break;
        default:
          this.scrollHeight = 'calc(100vh - 310px)';
        break;
      }
    }


  }

  @ViewChild('tabularContainer', { static: true }) tableContainer: HTMLDivElement;
  @ViewChild('paginator', { static: true }) paginator: Paginator


  public isMobile: boolean = false;

  public ResponsePagination = new Pagination();
  public displayFilterMenu: boolean = false;

  public defaultMomentDateRange: any = [moment().subtract(1, 'hours'), moment()];
  public exportFilename: string = "export"; //default

  public datePickerStartView: string = "month";
  public datePickerType: string = "both";

  public initialLoad = true;
  public dateSelectionChanged = false;

  // Table parameters
  loading: boolean = false;
  totalRecords: number;
  limit: number = 15;
  offset: number = 0;
  sort: string;
  page: number = 0;
  dataKey: string;

  filterParams = {
    startDateTime: '',
    endDateTime: ''
  };

  sharedColumns = [
    { field: 'AirTemperature.Min', header: 'Min temp °C', width: '80px', default: true, sortable: false, tooltip: '', order: 10 },
    { field: 'AirTemperature.MinTime', header: 'Min temp time', width: '150px', default: false, sortable: false, tooltip: "", order: 20 },
    { field: 'AirTemperature.Max', header: 'Max temp °C', width: '80px', default: true, sortable: false, tooltip: '', order: 25 },
    { field: "AirTemperature.MaxTime", header: 'Max temp time', width: '180px', default: false, sortable: false, tooltip: "", order: 30 },
    { field: 'AirTemperature.Avg', header: 'Avg temp °C', width: '80px', default: false, sortable: false, tooltip: "", order: 40 },
    { field: 'RelativeHumidity.Min', header: 'Min RH %', width: '80px', default: true, sortable: false, tooltip: "", order: 50 },
    { field: 'RelativeHumidity.MinTime', header: 'Min RH time', width: '180px', default: false, sortable: false, tooltip: "", order: 60 },
    { field: 'RelativeHumidity.Max', header: 'Max RH %', width: '80px', default: true, sortable: false, tooltip: "", order: 70 },
    { field: "RelativeHumidity.MaxTime", header: 'Max RH time', width: '180px', default: false, sortable: false, tooltip: "", order: 80 },
    { field: 'RelativeHumidity.Avg', header: 'Avg RH %', width: '80px', default: false, sortable: false, tooltip: "", order: 90 },
    { field: 'Rainfall', header: 'Rain mm', width: '80px', default: true, sortable: false, tooltip: "", order: 100 },
    //Wind should be here ideally - dynamic at moment
    { field: 'SoilTemperature.Min', header: 'Min soil temp °C', width: '80px', default: true, sortable: false, tooltip: "", order: 110 },
    { field: 'SoilTemperature.MinTime', header: 'Min soil temp time', width: '180px', default: false, sortable: false, tooltip: "", order: 120 },
    { field: 'SoilTemperature.Max', header: 'Max soil temp °C', width: '80px', default: true, sortable: false, tooltip: "", order: 130 },
    { field: "SoilTemperature.MaxTime", header: 'Max soil temp time', width: '180px', default: false, sortable: false, tooltip: "", order: 140 },
    { field: 'SoilTemperature.Avg', header: 'Avg soil temp °C', width: '80px', default: false, sortable: false, tooltip: "", order: 150 },
    //Barometric if available DBCA at moment    
    { field: 'TotalSolarExposureMJ', header: 'Solar exposure MJ', width: '80px', default: true, sortable: false, tooltip: "", order: 160 },
    { field: 'PanEvaporation', header: 'Pan evaporation mm', width: '100px', default: true, sortable: false, tooltip: "", order: 170 },
    { field: 'Evapotranspiration.Short', header: 'ETo short crop mm', width: '80px', default: true, sortable: false, tooltip: "", order: 180 },
    { field: 'Evapotranspiration.Tall', header: 'ETo tall crop mm', width: '80px', default: true, sortable: false, tooltip: "", order: 190 },
    { field: 'FrostCondMinutes', header: 'Cold duration mins.', width: '80px', default: true, sortable: false, tooltip: "", order: 200 },
    { field: 'FrostCondStartTime', header: 'Cold start time', width: '150px', default: false, sortable: false, tooltip: "", order: 210 },
    { field: 'HeatCondMinutes', header: 'Heat duration mins', width: '80px', default: true, sortable: false, tooltip: "", order: 211 },
    { field: 'HeadCondStartTime', header: 'Heat start time', width: '150px', default: false, sortable: false, tooltip: "", order: 212 },
    { field: 'ErosionConditionMinutes', header: 'High wind mins', width: '80px', default: true, sortable: false, tooltip: "", order: 213 },
    { field: 'ErosionConditionStartTime', header: 'High wind start time', width: '150px', default: false, sortable: false, tooltip: "", order: 214 },
    { field: 'RichardsonUnits', header: 'Richardson units', width: '80px', default: true, sortable: false, tooltip: 'Richardson units', order: 215 },
    { field: 'ChillHours', header: 'Chill hours', width: '80px', default: true, sortable: false, tooltip: '', order: 216 },
    { field: 'DewPoint.Min', header: 'Min dew point', width: '80px', default: false, sortable: false, tooltip: "", order: 230 },
    { field: 'DewPoint.MinTime', header: 'Min dew point time', width: '80px', default: false, sortable: false, tooltip: "", order: 240 },
    { field: 'DewPoint.Max', header: 'Max dew point', width: '80px', default: false, sortable: false, tooltip: "", order: 245 },
    { field: "DewPoint.MaxTime", header: 'Max dew point time', width: '80px', default: false, sortable: false, tooltip: "", order: 250 },
    { field: 'DewPoint.Avg', header: 'Avg dew point', width: '80px', default: false, sortable: false, tooltip: "", order: 260 },
    { field: 'DeltaT.Min', header: 'Min delta T', width: '80px', default: false, sortable: false, tooltip: "", order: 270 },
    { field: 'DeltaT.MinTime', header: 'Min delta T time', width: '180px', default: false, sortable: false, tooltip: "", order: 280 },
    { field: 'DeltaT.Max', header: 'Max delta T', width: '100px', default: false, sortable: false, tooltip: "", order: 290 },
    { field: "DeltaT.MaxTime", header: 'Max delta T time', width: '180px', default: false, sortable: false, tooltip: "", order: 300 },
    { field: 'DeltaT.Avg', header: 'Avg delta T', width: '80px', default: false, sortable: false, tooltip: "", order: 310 },
    { field: 'WetBulb.Min', header: 'Min Wet bulb', width: '80px', default: false, sortable: false, tooltip: "", order: 320 },
    { field: 'WetBulb.MinTime', header: 'Min wet bulb time', width: '180px', default: false, sortable: false, tooltip: "", order: 330 },
    { field: 'WetBulb.Max', header: 'Max wet bulb', width: '80px', default: false, sortable: false, tooltip: "", order: 340 },
    { field: "WetBulb.MaxTime", header: 'Max wet bulb time', width: '180px', default: false, sortable: false, tooltip: "", order: 350 },
    { field: 'WetBulb.Avg', header: 'Avg wet bulb', width: '80px', default: false, sortable: false, tooltip: "", order: 360 },
    { field: 'BarometricPressure.Min', header: 'Barometric pressure min hPa', width: '80px', default: true, sortable: false, tooltip: '', order: 361 },
    { field: 'BarometricPressure.MinTime', header: 'Barometric pressure min time', width: '180px', default: false, sortable: false, tooltip: '', order: 362 },
    { field: 'BarometricPressure.Max', header: 'Barometric pressure max hPa', width: '80px', default: true, sortable: false, tooltip: '', order: 363 },
    { field: 'BarometricPressure.MaxTime', header: 'Barometric pressure max time', width: '180px', default: false, sortable: false, tooltip: '', order: 364 },
    { field: 'BarometricPressure.Avg', header: 'Barometric pressure Avg hPa', width: '80px', default: false, sortable: false, tooltip: '', order: 365 },
    //{ field: 'Observations', header: 'Observations' },
    { field: 'ErrorCount', header: 'Errors', width: '80px', default: false, sortable: false, tooltip: "", order: 370 },
    { field: 'MinBatteryVoltage', header: 'Min battery voltage', width: '80px', default: true, sortable: false, tooltip: "", order: 380 },
    { field: 'MinBatteryVoltageDateTime', header: 'Min battery voltage time', width: '80px', default: false, sortable: false, tooltip: "", order: 390 },

  ];


  sharedColumnsMonthlyYearly = [
    { field: 'AirTemperature.MinAvg', header: 'Min avg temp °C', width: '80px', default: false, sortable: false, tooltip: '', order: 11 },
    { field: 'AirTemperature.MaxAvg', header: 'Max avg temp °C', width: '80px', default: false, sortable: false, tooltip: '', order: 12 },
    { field: 'RelativeHumidity.MinAvg', header: 'Min avg RH %', width: '80px', default: false, sortable: false, tooltip: '', order: 51 },
    { field: 'RelativeHumidity.MaxAvg', header: 'Max avg RH %', width: '80px', default: false, sortable: false, tooltip: '', order: 52 },
    { field: 'DewPoint.MinAvg', header: 'Min avg dew point', width: '80px', default: false, sortable: false, tooltip: '', order: 231 },
    { field: 'DewPoint.MaxAvg', header: 'Max avg dew point', width: '80px', default: false, sortable: false, tooltip: '', order: 232 },
    { field: 'SoilTemperature.MinAvg', header: 'Min avg soil temp °C', width: '80px', default: false, sortable: false, tooltip: '', order: 111 },
    { field: 'SoilTemperature.MaxAvg', header: 'Max avg soil temp °C', width: '80px', default: false, sortable: false, tooltip: '', order: 112 },
    //  { field: 'BarometricPressure.Min', header: 'Barometric pressure min', width: '50px', default: true, sortable: false,  tooltip: '' , order: 113 },
    //  { field: 'BarometricPressure.MinTime', header: 'Barometric pressure min time', width: '180px', default: false, sortable: false,  tooltip: '' , order: 114 },
    //  { field: 'BarometricPressure.Max', header: 'Barometric pressure max', width: '80px', default: true, sortable: false,  tooltip: '' , order: 115 },
    //  { field: 'BarometricPressure.MaxTime', header: 'Barometric pressure max time', width: '180px', default: false, sortable: false,  tooltip: '' , order: 116 },
    { field: 'DeltaT.MinAvg', header: 'Min avg delta T', width: '80px', default: false, sortable: false, tooltip: '', order: 271 },
    { field: 'DeltaT.MaxAvg', header: 'Max avg delta T', width: '80px', default: false, sortable: false, tooltip: '', order: 272 },
    { field: 'WetBulb.MinAvg', header: 'Min avg wet bulb', width: '80px', default: false, sortable: false, tooltip: '', order: 321 },
    { field: 'WetBulb.MaxAvg', header: 'Max avg wet bulb', width: '80px', default: false, sortable: false, tooltip: '', order: 322 },
    { field: 'FrostConditionDays', header: 'Cold days', width: '80px', default: true, sortable: false, tooltip: '', order: 210 },
    { field: 'HeatConditionDays', header: 'Heat days', width: '80px', default: true, sortable: false, tooltip: '', order: 212 },
    { field: 'ErosionConditionDays', header: 'High wind days', width: '80px', default: true, sortable: false, tooltip: '', order: 214 },
    { field: 'RainDays', header: 'Rain days', width: '50px', default: true, sortable: false, tooltip: '', order: 101 },
    { field: 'StationAvailabilityTo9AM', header: 'Availability % to 9am', width: '90px', default: true, sortable: false, order: 400 },
    { field: 'StationAvailabilitySince12AM', header: 'Availability % since 12am', width: '90px', default: true, sortable: false, order: 500 }
  ];

  data: any;
  selectedData: any;

  cols: any[];

  // when we first get a station we dont know what wind probes are avaiable
  // so when we get response they will get dynamically added on the first run
  windProbesForStation: boolean[];
  windProbesInitialized: boolean = false;
  selectedColumns: any[];

  constructor(
    private weatherService: WeatherService,
    private loggingService: LoggingService,
    private changeDetectionRef: ChangeDetectorRef,
    private stateService: StateService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private deviceService: DeviceDetectorService
  ) {

  }

  ngOnInit() {

    this.isMobile = this.deviceService.isMobile();
    switch (this.summaryType) {

      case 'MINUTE':
        this.dataKey = 'DateTimeLocal';
        this.cols = [
          { field: 'DateTimeLocal', header: 'Date time', width: '145px', default: true, sortable: true, tooltip: "", order: 1 },
          { field: 'AirTemperature', header: 'Temp °C', width: '100px', default: true, sortable: false, tooltip: "", order: 10 },
          { field: 'RelativeHumidity', header: 'Relative humidity %', width: '100px', default: true, sortable: false, tooltip: "", order: 20 },
          { field: 'RainFall', header: 'Rain mm', width: '100px', default: true, sortable: false, tooltip: "", order: 30 },
          { field: 'SoilTemperature', header: 'Soil temp. °C', width: '100px', default: true, sortable: false, tooltip: "", order: 140 },
          { field: 'SolarIrradiance', header: 'Solar irradiance W/m²', width: '100px', default: true, sortable: false, tooltip: "", order: 150 },
          { field: 'DewPoint', header: 'Dew point °C', width: '100px', default: false, sortable: false, tooltip: "", order: 160 },
          { field: 'WetBulb', header: 'Wet bulb °C', width: '100px', default: false, sortable: false, tooltip: "", order: 170 },
          { field: 'BarometricPressure', header: 'Barometric pressure hPa', width: '100px', default: true, sortable: false, tooltip: "", order: 180 },
          { field: 'BatteryVoltage', header: 'Battery voltage', width: '100px', default: false, sortable: false, tooltip: "", order: 190 }
        ];

        break;

      case 'HOURLY':
        this.dataKey = "Period.ToUTC";
        this.cols = [
          { field: "Period.ToUTC", type: 'Date', format: "DD-MM-YYYY HH:mm", header: 'Date time', width: '130px', default: true, sortable: true, order: 1 },
          { field: 'AvailabilityHoursTime', header: 'Availability hours', width: '80px', default: true, sortable: false, order: 2000 }
        ];

        this.cols = this.cols.concat(this.sharedColumns);
        //this.cols.sort(function (a,b) {
        //  return (a.order < b.order) ? -1 : 1;
        //})
        break;

      case 'DAILY':
        this.dataKey = "Period.FromUTC";
        this.cols = [
          { field: 'Period.FromUTC', type: 'Date', format: 'DD-MM-YYYY', header: 'Date', width: '130px', default: true, sortable: true, order: 1 },
          { field: 'StationAvailabilityTo9AMTime', header: 'Availability to 9am (hours)', width: '80px', default: true, sortable: false, order: 400 },
          { field: 'StationAvailabilitySince12AMTime', header: 'Availability since 12am (hours)', width: '80px', default: true, sortable: false, order: 500 },
          { field: 'PanEvaporation12AM', header: 'Pan Evaporation 12am', width: '100px', default: true, sortable: false, order: 175 }
        ];

        this.cols = this.cols.concat(this.sharedColumns);

        this.datePickerStartView = "month";
        this.datePickerType = "calendar";
        break;

      case 'MONTHLY':
        this.dataKey = "Period.FromUTC";
        this.cols = [
          { field: "Period.FromUTC", type: 'Date', format: "MM-YYYY", header: 'Month', width: '80px', default: true, sortable: true, order: 1 }
        ];
        this.cols = this.cols.concat(this.sharedColumns, this.sharedColumnsMonthlyYearly);
        this.datePickerStartView = "year";
        this.datePickerType = "calendar";
        break;

      case 'YEARLY':
        this.dataKey = "Period.Year";
        this.cols = [
          { field: "Period.Year", header: 'Year', width: '50px', default: true, sortable: true, order: 1 }
        ];

        this.cols = this.cols.concat(this.sharedColumns, this.sharedColumnsMonthlyYearly);

        this.datePickerStartView = "multi-years";
        this.datePickerType = "calendar";


        break;

    }

    //this.cols = this.cols.sort(function (a,b) {
    //  return (a.order > b.order) ? -1 : (a.order < b.order) ? 1 : 0 ;
    //});


    this.setDefaultDates();

    this.windProbesForStation = [];

    // Set the columns to the ones we want displayed by default.
    this.selectedColumns = this.cols.filter(column => column.default === true);
    //

    this.scrollHeight =  'calc(100vh - 50vh)';
    
  }

  ngAfterViewChecked() {
    // added in to stop expression errors
    //https://github.com/angular/angular/issues/6005
    this.changeDetectionRef.detectChanges();
  }



  ngOnChanges(changes: SimpleChanges) {

    if (changes.active !== undefined && changes.active.currentValue === true && changes.active.previousValue === false) {
      this.initialLoad = false; // stop any more calls from changes on dates
      //if there is a drill down filtering date range this take priority
      if ((changes.dateRange !== undefined && changes.dateRange !== null)) {
        if (changes.dateRange.currentValue !== undefined && changes.dateRange.currentValue !== null) {
          this.filterParams.startDateTime = changes.dateRange.currentValue[0].format("YYYY-MM-DDTHH:mm:ss");
          this.filterParams.endDateTime = changes.dateRange.currentValue[1].format("YYYY-MM-DDTHH:mm:ss");

          this.defaultMomentDateRange = [changes.dateRange.currentValue[0], changes.dateRange.currentValue[1]];

        } else {
          this.setDefaultDates();
        }


        this.loadTabularDataLazy({
          filters: {},
          first: 0,
          globalFilter: null,
          multiSortMeta: null,
          rows: 200,
          sortField: null,
          sortOrder: 1,
          page: 0
        });
        //this.onDateSelection(this.defaultMomentDateRange);

      }


      // If the stationcode has changed reset the fields
      if (changes.stationCode !== undefined) {
        if (changes.stationCode.previousValue !== undefined && changes.stationCode.previousValue !== changes.stationCode.currentValue) {

          //this.filterParams.startDateTime = "";
          //this.filterParams.endDateTime = "";

          //TODO: betterway for knowing what probes are on a station
          //because station has changed reset the windprobe columns
          this.windProbesInitialized = false;
          this.removeDynamicAddedWindColumns();
          this.selectedColumns = this.cols;

          this.setWindProbesForStation();

          this.setDefaultDates();
          // this.onDateSelection(this.defaultMomentDateRange);
        }
      }
    }
  }

  removeDynamicAddedWindColumns() {
    //Assign new copy without windprobes that are added dynamically
    this.cols = this.cols.filter(f => f.field.toLowerCase().substr(0, 10) !== "windprobes");
  }

  updateSelectedColumns(event) {

    this.loggingService.logToConsole("Sort the selected columns");

    this.selectedColumns = this.selectedColumns.sort(function (a, b) {
      return (a.order < b.order) ? -1 : 1;
    });


  }

  /**
   * Set the default date ranges doe the table depending on the interval summary type
   * that has been set on the input binding.
   */
  setDefaultDates() {
    switch (this.summaryType) {
      case 'MINUTE':
        this.defaultMomentDateRange = [moment().subtract(1, 'hours'), moment()];
        break;
      case 'HOURLY':
        this.defaultMomentDateRange = [moment().subtract(36, 'hours'), moment()];
        break;
      case 'DAILY':
        this.defaultMomentDateRange = [moment().subtract(31, 'days'), moment()];
        break;
      case 'MONTHLY':
        this.defaultMomentDateRange = [moment().subtract(30, 'months'), moment()];
        break;
      case 'YEARLY':
        this.defaultMomentDateRange = [moment().subtract(18, 'years'), moment()];
        break;
    }


    this.filterParams.startDateTime = this.defaultMomentDateRange[0].format("YYYY-MM-DDTHH:mm:ss"); //toISOString().split('.')[0] + "Z";
    this.filterParams.endDateTime = this.defaultMomentDateRange[1].format("YYYY-MM-DDTHH:mm:ss");   //toISOString().split('.')[0] + "Z";
  }


  setWindProbesForStation(){
    if(this.windProbesForStation == undefined)
      this.windProbesForStation = [];
    
    this.weatherService.getStation(this.stationCode.toUpperCase()).subscribe(
      data => {
        this.windProbesForStation[0] = (data.WindProbeHeights[0] != null && data.WindProbeHeights[0] != undefined);
        this.windProbesForStation[1] = (data.WindProbeHeights[1] != null && data.WindProbeHeights[1] != undefined);
        this.windProbesForStation[2] = (data.WindProbeHeights[2] != null && data.WindProbeHeights[2] != undefined);

    })
  }


  /*
  * Custom Export
  * Component by default has export build in using <button type="button" (click)="dt.exportCSV()">Export All</button>
  * but will not allow for the custom formatting of columns, so this function has been written to allow more flexibility.
  * exports everything by default
  */
  export(selectedItems: boolean = false) {
    let data = this.dataTable.value; //First page

    if (selectedItems) {
      data = this.dataTable.selection || [];
      this.exportCSV(data);
    } else {
      this.getSummaryExport();
    }
  }

  exportCSV(data) {

    if (data.length === 0) {
      this.stateService.openApplicationModal({ Title: "Export", Message: "There is no data or no selection made for the export", Colour: 'blue' });
      return;
    }

    this.googleAnalyticsService.emitEvent("TabularData", "Export", this.summaryType + " - " + this.stationCode);

    let dt = this.dataTable;
    let csv = '\ufeff';
    let csvSeparator = ',';


    // Timestamp the export with the prefix of the station and summary type also included
    this.exportFilename = this.stationCode + '_' + this.summaryType.toLowerCase() + '_' + moment().format('YYYYMMDDHHmmss');

    //*let objectutils = dt.objectUtils; // helper object

    // headers
    for (let i = 0; i < dt.columns.length; i++) {
      let column = dt.columns[i];
      if (column.field) {
        csv += '"' + (column.header || column.field) + '"';
        if (i < (dt.columns.length - 1)) {
          csv += csvSeparator;
        }
      }
    }

    // body
    data.forEach((record, i) => {
      csv += '\n';
      for (let i = 0; i < dt.columns.length; i++) {
        let column = dt.columns[i];
        if (column.field) {
          let cellData = ObjectUtils.resolveFieldData(record, column.field);
          // Addtional format control
          if (column.type !== undefined && cellData !== null) {
            if (column.type === 'Date' && column.format !== undefined) {
              cellData = moment(cellData).format(column.format);
            }
          };
          //end

          if (cellData != null)
            cellData = String(cellData).replace(/"/g, '""');
          else
            cellData = '';
          csv += '"' + cellData + '"';
          if (i < (dt.columns.length - 1)) {
            csv += csvSeparator;
          }
        }
      }
    });

    // Generate download
    let blob = new Blob([csv], {
      type: 'text/csv;charset=utf-8;'
    });

    if (window.navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(blob, this.exportFilename + '.csv');
    }
    else {
      let link = document.createElement("a");
      link.style.display = 'none';
      document.body.appendChild(link);
      if (link.download !== undefined) {
        link.setAttribute('href', URL.createObjectURL(blob));
        link.setAttribute('download', this.exportFilename + '.csv');
        link.click();
      }
      else {
        csv = 'data:text/csv;charset=utf-8,' + csv;
        window.open(encodeURI(csv));
      }

      document.body.removeChild(link);
    }
  }


  /*
   * Function to return the value from the JSON object dynamically
   * TODO://Possibly make more flexible currently two levels with json and when 4 levels assumes array
   * used to allow dynamic grid columns and selectable functionality
   */
  getFieldValue(item, object) {

    let propertyName = item.field;

    var parts = propertyName.split("."),
      length = parts.length,
      i,
      property = object || this;

    if (length <= 2) {
      for (i = 0; i < length; i++) {
        property = property[parts[i]];
      }
    } else {
      try {
        property = property[parts[0]][parts[1]][parts[2]];
      }
      catch {
        //todo: determine a better way
        property = "";
      }
    }

    // Have the value now apply a format if required.
    if (item.type !== undefined) {
      if (item.type === 'Date' && item.format !== undefined) {
        property = moment(property).format(item.format);
      }
    }

    return property;
  }


  isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key))
        return false;
    }
    return true;
  }

  /**
   * Load the data taking into consideration pagination
   */
  loadTabularDataLazy(event: any) { //LazyLoadEvent
    //console.log('loadTabuleDataLazy : ' + moment().toISOString());
    // Only filter if active.
    if (this.active) {


      this.loading = true;

      /*
      if (this.dateSelectionChanged === true) {
        event.filters = {};
        event.first = 0;
      //  event.globalFilter: null,
       // event.multiSortMeta: null,
        event.rows = 200;
//        event.sortField = null,
        event.sortOrder = 1,
        event.page = 0;

      //  this.dataTable.reset(); // reset the underlying table
        this.paginator._first = 0;
        this.dateSelectionChanged = false ; //reset flag
      }*/


      //in a real application, make a remote request to load data using state metadata from event
      //event.first = First row offset
      //event.rows = Number of rows per page
      //event.sortField = Field name to sort with
      //event.sortOrder = Sort order as number, 1 for asc and -1 for dec
      //filters: FilterMetadata object having field as key and filter value, filter matchMode as value
      this.page = event.page === undefined ? this.page : event.page;

      this.offset = this.page * event.rows;

      if (event.filters !== undefined && !this.isEmpty(event.filters)) {
        this.sort = (event.sortOrder === -1 ? '' : '-') + 'periodFrom';
      } else if (event.sortField !== undefined && event.sortField !== null) {
        this.sort = (event.sortOrder === -1 ? '' : '-') + 'periodFrom';
      } else if ((event.sortField === undefined || event.sortField === null) && this.sort === undefined) {
        //default sort order
        this.sort = '-periodFrom';
      }

     
      this.setWindProbesForStation();

      this.weatherService.getSummaries(this.limit, this.offset, this.stationCode.toUpperCase(), this.filterParams.startDateTime, this.filterParams.endDateTime, this.summaryType, this.sort).subscribe(
        data => {
          this.data = data.collection;
          // Dyanmically create the windprobe columns as this changes from station to station
          // we can use the response to create the columns if they are not already mapped.
          this.initWindProbeColumns();
          // set current params for pagination
          this.totalRecords = data.metadata.collection.count;
          this.limit = data.metadata.collection.limit;
          this.offset = data.metadata.collection.offset;
        },
        err => { this.onResponseError(err) },
        () => this.onCompletedGetResponse());
    }

  }

  getSummaryExport() {
    this.weatherService.getSummaries(1000, 0, this.stationCode, this.filterParams.startDateTime, this.filterParams.endDateTime, this.summaryType, this.sort).subscribe(
      data => {
        this.exportCSV(data.collection);
      },
      err => { this.onResponseError(err) });
  }

  /**
   * Helper function to dynamically add on the relevant wind probes for the station
   */
  initWindProbeColumns() {

    if (!this.windProbesInitialized) {
      //let self = this;
      if (this.data[0] !== undefined) {
        this.data[0].getWindProbes().forEach((item, index, arr) => {

          if(this.windProbesForStation[index]){

            for (var property in item) {

              if (item.hasOwnProperty(property) && item[property] != null) {
                if (this.summaryType == "MINUTE") {
                  if (property.toLowerCase() == "maxdirectiondegrees" || property.toLowerCase() == "maxcompasspoint" || property.toLowerCase() == "maxtime") {
                    continue;
                  }
                }
                if (property.toLowerCase() == "maxtime" || property.toLowerCase() == "mintime") {
                  this.cols.push({ field: 'WindProbes.' + index + '.' + property, header: 'Wind ' + property.replace(/([A-Z])/g, ' $1').trim() + ' @' + item.Height + 'm', type: 'Date', format: "DD-MM-YYYY HH:mm", width: '160px', default: true, order: 101 });
                } else {
                  if (property.toLowerCase() != 'height' ) {
                    if (property.toLowerCase() == 'avgspeed' || property.toLowerCase() == 'maxspeed' ) {
                      this.cols.push({ field: 'WindProbes.' + index + '.' + property, header: 'Wind ' + property.replace(/([A-Z])/g, ' $1').trim() +" km/h " + ' @' + item.Height + 'm', width: '140px', default: (property.toLowerCase() !== 'height'), order: 101 });
                    }else{
                      this.cols.push({ field: 'WindProbes.' + index + '.' + property, header: 'Wind ' + property.replace(/([A-Z])/g, ' $1').trim()  + ' @' + item.Height + 'm', width: '140px', default: (property.toLowerCase() !== 'height'), order: 101 });
                    }
                  }
                }
              }
            }

          }
        });

      }
      
      this.windProbesInitialized = true;


      this.cols = this.cols.sort(function (a, b) {
        return (a.order < b.order) ? -1 : (a.order > b.order) ? 1 : 0;
      });

      this.selectedColumns = this.cols.filter(column => column.default == true); //need to refresh as added wind columns
      this.selectedColumns = this.selectedColumns.sort(function (a, b) {
        return (a.order < b.order) ? -1 : (a.order > b.order) ? 1 : 0;
      });
    }

  }

  closeDatePickerOverlay() {
    if (this.displayFilterMenu) {
      this.displayFilterMenu = false;
    }
  }

  /**
   * When there is an error with the reponse we will reset the spinner so the table stops loading and the interceptor for the generic
   * error handling will show the message why there was an error.
   */
  onResponseError(response) {
    this.loading = false;
  }


  onCompletedGetResponse() {
    this.loading = false;
  }

  /**
   * Big X button to close the component by removing the active class and moves the component
   * off the screen.
   */
  closeTable() {
    this.active = false;
    this.activeChange.emit(false);

    if (this.displayFilterMenu) {
      this.displayFilterMenu = false;
    }
  }

  /*getScrollHeight() {
    try {
    let containerDiv = (document.getElementsByClassName('ui-table')[0] as any).offsetHeight;
    containerDiv =  (containerDiv - 135);
    //if ( containerDiv > 346) {
   //   containerDiv = 346; //Max settings
   // }
    return containerDiv.toString() + 'px';
    }
    catch(e) {
      this.loggingService.logToConsole("cant get container div height");
      return '500px';
    }
  }*/


  /**
   * On the selection of date parameters.
   * @param params
   */
  onDateSelection(params) {

    // Assign selected dates removing milliseconds
    this.filterParams.startDateTime = params[0].format("YYYY-MM-DDTHH:mm:ss"); //toISOString().split('.')[0] + "Z";
    this.filterParams.endDateTime = params[1].format("YYYY-MM-DDTHH:mm:ss");   //toISOString().split('.')[0] + "Z";
    this.dateSelectionChanged = true;

    // Init the LoadLazy method to fetch the data.
    // LoadLazy will be called by the table when pagination is used.
    let event = {
      filters: {},
      first: 0,
      globalFilter: null,
      multiSortMeta: null,
      rows: 200,
      sortField: null,
      sortOrder: 1,
      page: 0
    };

    this.dataTable.reset(); // reset the underlying table
    this.paginator._first = 0;
    //event.sortOrder = 0;

    this.loadTabularDataLazy(event);
    this.displayFilterMenu = false;
  }


  toggleFilterMenu(caller) {
    this.displayFilterMenu = !this.displayFilterMenu;
  }


  toggleTable(metric: string) {
  
    if (metric !== this.summaryType) {
      this.tableMetricChange.emit({ 'summaryType': metric, 'dateRange': null });
    }
  }


  /*
   * Drill down the tabular data based on the current table that is shown.
   */
  drillDownData(rowData) {

    const fromDate = moment(rowData.Period.FromUTC);
    const toDate = moment(rowData.Period.ToUTC);

    //#598 toDate has subtractions to match the filtered date range
    switch (this.summaryType) {
      case 'MINUTE':
        //no more breakdown
        break;
      case 'HOURLY':
        //set the default date
        this.tableMetricChange.emit({ 'summaryType': 'MINUTE', 'dateRange': [fromDate.add(1, 'minute'), toDate] });
        break;
      case 'DAILY':
        this.tableMetricChange.emit({ 'summaryType': 'HOURLY', 'dateRange': [fromDate.add(1, 'minute'), toDate] });
        break;
      case 'MONTHLY':
        this.tableMetricChange.emit({ 'summaryType': 'DAILY', 'dateRange': [fromDate, toDate.subtract(1, 'days')] });
        break;
      case 'YEARLY':
        this.tableMetricChange.emit({ 'summaryType': 'MONTHLY', 'dateRange': [fromDate,  toDate.subtract(1, 'days')] });
        break;
    }

    return false;
  }

}
