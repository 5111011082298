import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

/*
 * Available Endpoints in the WEATHER API
 */
const ENDPOINT_STATUS= 'status';

@Injectable()
export class StatusService {

    apiUrl = environment.APIURI;

    constructor(private http: HttpClient) { }

    getWeatherSystemStatus(): Observable<any> {

    return this.http.get<any>(this.apiUrl + ENDPOINT_STATUS)
        .pipe(map(res => {
            return res.data["STATUS"];
        }));
    }
}