import { Component, OnInit, AfterViewInit, OnDestroy, HostListener, ViewChild, DebugEventListener } from '@angular/core';
import { StateService } from '../services/state/state.service';
import { PanelSetting } from '../shared/models/panel.setting.model';
import { Subscription } from "rxjs";

import { LoggingService } from '../services/logging/logging.service';
import { DataCache } from '../services/data-cache/data-cache.service';
import { WeatherService } from '../services/weather/weather.service'; // included to call events on the fly othewise datacache should be used.
import { RadarService } from '../services/radar/radar.service';

import { StationLatestModel } from '../models/station-latest.model';
import { StationNearby } from '../services/weather/models/station-nearby.model';
import { StationModel } from '../models/station.model';
import { NgbTabset } from "@ng-bootstrap/ng-bootstrap";
import { Router } from '@angular/router';

import * as moment from 'moment';
import { BoMStationModel } from '../services/gis-to-web/models/bom-station.model';


@Component({
  selector: 'app-station-sidebar',
  templateUrl: './station-sidebar.component.html',
  styleUrls: ['./station-sidebar.component.scss'],
  providers: [LoggingService]
})
export class StationSidebarComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('tabs', {static: true})
  public tabs: NgbTabset;

  message: any;
  subscription: Subscription;
  radarSubscription: Subscription;
  activeIdString: string = 'tabSummary';

  currentStationCode: string;
  isStationSelected: false;
  public isRadarTimedOut = false;

  public panelSettings = new PanelSetting(false);
  public stationLatestModel = new StationLatestModel(null);
  public stationNearby = new StationNearby();
  public radarData;

  public forecastHourlyData;

  public isBomStation = false;

  public stationModel: StationModel;
  public BoMstationModel: BoMStationModel;

  screenHeight: number;
  screenWidth: number;
  public stationLastUpdated: string;

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }

  constructor(
    private stateService: StateService,
    private dataCacheService: DataCache,
    private weatherService: WeatherService,
    private radarService: RadarService,
    private loggingService: LoggingService,
    private route: Router
  ) {
    this.stationModel = null;
    this.onResize();
  }


  ngOnInit() {
  }


  ngAfterViewInit() {

    const self = this;
    setTimeout(function() {
      self.panelSettings = self.stateService.panelSettings;
    }, 500);

    // Timedbased delay to avoid ExpressionChangedAfterItHasBeenCheckedError in development mode
    // this doesn't occur in production enabled mode.
    this.subscription = this.stateService.getMessage().subscribe(message => {
      setTimeout(function() {
        self.onStationMarkerClicked(message);
      }, 500);
    });

    this.radarSubscription = this.stateService.onRadarMapLocationClicked().subscribe(
          data => {this.radarData = null; this.onRadarMapAreaClicked(data)});
  }

 

  /**
   * Unsubscrive to ensure we dont repeat the subscriptions and to avoid memory leaks
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.radarSubscription.unsubscribe();
  }

  /**
   * Slides the panel in and out of focus. The event updates the states panelSettings so that the
   * control will conditionally alter he active class so the CSS will transition between UI states.
   */
  onCloseButtonClicked() {
    this.stateService.panelSettings.isOpen = !this.stateService.panelSettings.isOpen;
  }

  /**
   * Markers will pass in a station object when they have been clicked and the relevant data shown in th side panel. If its an area
   * within the RADAR converage then it will be a Virtual station and the summary tabe disabled.
   */
  onStationMarkerClicked(station) {
 
    this.currentStationCode = station.id !== undefined ? station.id : station.Code ;
    this.isBomStation = false;
    
    if (this.currentStationCode.length > 13 && this.currentStationCode.substr(0, 12).toUpperCase() === 'BOM_STATION_') {
     // this.currentStationCode = 'BOM_STATION';
      //station.code = 'BOM_STATION'; //HACKY
      this.isBomStation = true;
    }

  
    if (this.isBomStation) { 
      this.dataCacheService.getBoMStationModel(station.id).subscribe(
        data => {
           this.BoMstationModel = data;

           this.setActiveTab('tabSummary');
         }
      );
      
    }  else {

    this.dataCacheService.getStationNearbyModel(station.Latitude, station.Longitude).subscribe(
      data => { 
        if (data !== null) {
          this.stationNearby = data.filter(s => s.Code !== station.Code);
        } else {
          this.stationNearby == null;
        }

       },
      err => { this.loggingService.logToConsole("station-sidebar, onStationMarkerClicked error getStationNearbyModel")}
    );



    //https://stackoverflow.com/questions/34827334/triggering-change-detection-manually-in-angular
  
    if (station.Code === 'VIRTUAL_STATION') {
      this.setActiveTab('tabRadar');
    } else if (this.isBomStation === false) {

       this.dataCacheService.getStationModel(station.Code).subscribe(
         data => { 
          this.stationModel = data;
          }
       );

        this.dataCacheService.getStationLatestModelsByCode(station.Code).subscribe(
          data => {
            this.stationLatestModel = data;
            this.stationLastUpdated = this.stationLatestModel.getLastUpdatedDateTime('DD-MM-YYYY HH:mm A');

            try {
              const currentDate = moment();

              // 2.
              let MaxTempSince9amStartDate;
              let MaxTempSince9amEndDate;

              if (currentDate.hour() >= 9 && currentDate.minute() > 0) { // Greater than 9AM
                MaxTempSince9amEndDate = currentDate.add(1, 'hour').startOf('hour'); // end of the current hour

                const dateObj = new Date();
                const dateStr = dateObj.toISOString().split('T').shift();
                const timeStr = '09:00';
                MaxTempSince9amStartDate = moment(dateStr + ' ' + timeStr);
              } else { // Previous Day until 9

                let dateObj = new Date();
                dateObj.addDays(-1);
                const dateStr = dateObj.toISOString().split('T').shift();
                const timeStr = '09:00';
                MaxTempSince9amEndDate = moment();
                // Round the end date to the end of the hour
                if (MaxTempSince9amEndDate.minute() || MaxTempSince9amEndDate.second() || MaxTempSince9amEndDate.millisecond()) {
                  MaxTempSince9amEndDate.add(1, 'hour').startOf('hour');
                }

                MaxTempSince9amStartDate = moment(dateStr + ' ' + timeStr);
              }

              // Max Temp since 9:00am
              this.weatherService.getExtremeEventByStationCode(station.Code, 'airTemperature', MaxTempSince9amStartDate.format('YYYY-MM-DDTHH:mm:ss'), MaxTempSince9amEndDate.format('YYYY-MM-DDTHH:mm:ss'), 'gt' , -50).subscribe(
                data => {
                  if (data.data.stations !== undefined && data.data.stations[0] !== undefined) {
                    this.stationLatestModel.MaxTempSince9am = data.data.stations[0].extremeValue;
                  }
                }
              );

              // 3.
              let MaxTempPast24HrsTo9amStartDate = MaxTempSince9amStartDate.add(-1, 'days');
              let MaxTempPast24HrsTo9amEndDate = moment(MaxTempSince9amStartDate).add(1, 'days');

              this.weatherService.getExtremeEventByStationCode(station.Code, 'airTemperature', MaxTempPast24HrsTo9amStartDate.format('YYYY-MM-DDTHH:mm:ss'), MaxTempPast24HrsTo9amEndDate.format('YYYY-MM-DDTHH:mm:ss'), 'gt' , -50).subscribe(
                data => {
                  if (data.data.stations !== undefined && data.data.stations[0] !== undefined) {
                    this.stationLatestModel.MaxTempPast24HrsTo9am = data.data.stations[0].extremeValue;
                  }
                }
              );
            }
            catch(err) {
              console.log('Error trying to calculate temperatures');
            }


          // end #533

          }
       );
       this.setActiveTab('tabSummary');
      }
    }

    // Forecasting
    this.dataCacheService.getForecastingByLocation(station.Latitude, station.Longitude).subscribe(
      data => {
        //this.forecastHourlyData = data;
        //this.forecastHourlyData = this.groupBy(data, (d) => d.ForecastDateTime.toLocaleDateString());
       
        //this.forecastHourlyData = this.groupBy(data, (d) => d.ForecastDateTimeFormatted);
        const forecastHourlyDataMap = Array.from(this.groupBy(data, (d) => d.ForecastDateTimeFormatted));
        //this.forecastHourlyData = Object.fromEntries((this.groupBy(data, (d) => d.ForecastDateTimeFormatted));
       // this.forecastHourlyData = {};
       // forecastHourlyDataMap.forEach((value, key) => {
       //   this.forecastHourlyData[key] = value;
       // });
       this.forecastHourlyData = forecastHourlyDataMap;

      },
      err => { this.loggingService.logToConsole("forecasting not available.")}
    );
  }
  

   /**
   * Group a JSON Collection by a certain field.
   * Makes it easier to seperate the weather stations via feature layers.
   * @param xs 
   * @param f 
   */
   //private groupBy(xs, f) {
   // return xs.reduce((r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r), {});
  //}
  private groupBy(xs, f) {
    return xs.reduce((r, v) => {
      const key = f(v);
      const group = r.get(key) || [];
      group.push(v);
      r.set(key, group);
      return r;
    }, new Map());
  }
  

  /**
   * Set the sidebar to show the current tab required. Stations show the summary tab and a radar point
   * will show the radar tab by default.
   * @param tabName string A string value that represents the tab name
   */
  setActiveTab(tabName: string) {
    //Have to use a combination of the tabs select and active Id string to make sure the tabs work correctly
    //otherwise there are times it doesnt actually switch focus. Clicking narby section without the tabs.select
    //doesnt work, everywhere elese the activeIdString is enough.
    if (this.tabs !== undefined) {
      this.tabs.select(tabName);
    }
    this.activeIdString = tabName;
  }

  /**
   * Event that gets passed the RADAR data when it has been received from the API Service.
   */
  onRadarMapAreaClicked(data) {
    this.radarData = data;
    this.isRadarTimedOut = false;

    // Summary reponse timed out in other environments so we delay the load until now.
    if (data !== null && data.Latitude !== undefined && data.Longitude !== undefined) {

      let radarStartMonthYear = moment().add(-1, 'year').format('YYYY-MM');
      let radarEndMonthYear = moment().format('YYYY-MM');

      this.radarService.getPrecipitationSummary(data.Latitude, data.Longitude, radarStartMonthYear, radarEndMonthYear).subscribe( data => {
        this.radarData.MonthlyRain = data.MonthlyRain;

      },
      err => {
        this.isRadarTimedOut = true;
      },
      () => { // Completed
        this.isRadarTimedOut = false;
      }
      );

    }

  }

  /**
   * Navigate to the stations dashboard 
   * */ 
  goToStationDashboard() {
    this.stateService.panelSettings.isOpen = false;
    //https://github.com/webpack/webpack-dev-server/issues/792
    //let self = this;
    //setTimeout(function(){ self.route.navigate(['station' , self.stationModel.Code] ); }, 500); //slight delay less jerkey transition to dashboard
    this.route.navigate(['/station', this.stationModel.Code]);
  }

  /**
   * Trigger a markers click event given the station objects as a reference
   * to filter through the map markers
   */
  showNearbyStation(station) {
    this.stateService.mapSettings.mapMarkers.find(f => f.stationCode == station.Code).marker.fire('click');
  }

}
