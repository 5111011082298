import { Component, OnInit, Input, SimpleChanges, HostListener } from '@angular/core';
import { StationLatestModel } from '../../models/station-latest.model';

@Component({
  selector: 'app-evapotranspiration',
  templateUrl: './evapotranspiration.component.html',
  styleUrls: ['./evapotranspiration.component.scss']
})
export class EvapotranspirationComponent implements OnInit {

    @Input() stationLatest: StationLatestModel; 

    @HostListener('document:click', ['$event'])
    onDocumentClick( event: Event ){
        const target = event.target as HTMLElement;
        if(!target.closest('.dropdown-hamburger') && !target.closest('.custom-dropdown-list')){
            this.dropdownVisible = false;
        }
    }

    //public stationLatestModel = new StationLatestModel(null); 

    isDataLoaded: boolean = false;
    etoSelect: number = 0;
    dropdownVisible: boolean = false;

    //etoModel: any = [] 

    etoModel: Array<{
        etoShort: number | null,
        etoTall: number | null,
        panEvap: number | null,
        label: string
    }> = [];

    constructor() { }

    ngOnInit(): void {
        this.initData();
        
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['stationLatest'] && !changes['stationLatest'].firstChange) {
            this.initData();
        }
    }

    initData(): void{
        if (this.stationLatest) {
            this.etoModel = [
                {
                    etoShort: this.stationLatest.EtoShortCrop,
                    etoTall: this.stationLatest.EtoTallCrop,
                    panEvap: this.stationLatest.PanEvaporation12AM,
                    label: "Since 12am",
                },
                {
                    etoShort: this.stationLatest.EtoShortCropTo3PM,
                    etoTall: this.stationLatest.EtoTallCropTo3PM,
                    panEvap: this.stationLatest.PanEvaporationTo3PM,
                    label: "24hrs to 3pm",
                }
            ];

            this.isDataLoaded = true;
        }
    }

    toggleDropdown(): void {
        this.dropdownVisible = !this.dropdownVisible;
    }

    setEtoDisplay(index: number): void{
        this.etoSelect = index;
        this.dropdownVisible = false;
    }
}