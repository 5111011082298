import { Component, OnInit, Input } from '@angular/core';
import * as Highcharts from 'highcharts';

import { WeatherChartService } from '../../../services/weather/weather.chart.service';

import * as moment from 'moment'; // Include the library have to include reference in angular CLI to js file

// Add additional modules required
require('highcharts/modules/data')(Highcharts);
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/highcharts-more')(Highcharts);


const HOUR_INTERVAL = 3600000;
const DAY_INTERVAL = 86400000;       // 24 * 3600 * 1000;
const MONTHLY_INTERVAL = 2628000000;
const YEARLY_INTERVAL = 31536000000; // 365 Days


const AVG_SERIES_COLOUR = '#00D034';
const MAX_SERIES_COLOUR = '#C02302';


@Component({
  selector: 'app-wind-speed-chart',
  templateUrl: './wind-speed-chart.component.html',
  styleUrls: ['./wind-speed-chart.component.scss']
})
export class WindSpeedChartComponent implements OnInit {

  station = { code: '', name: '' }; 
  
  chartWindData = [];

  Highcharts = Highcharts;
  isDataLoaded: boolean = false;
  tickInterval = DAY_INTERVAL;
  params;

  chartOptions = {
    credits: {
      enabled: false
    },
    yAxis: {
      title: {
        text: 'km/h'
      }
    },
    legend: {
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'middle'
    },
    series: [{
      "name": 'Avg',
      "data": []
    }, {
      "name": 'Max',
      "data": [],
      "color": "#a250f1"
    }],
    chart: {
      zoomType: 'x'
    },
    responsive: {
      rules: [{
        condition: {
          maxWidth: 500
        },
        chartOptions: {
          legend: {
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom'
          }
        }
      }]
    },
    exporting: {
      chartOptions: {
        credits: {
          enabled: true,
          text: 'Copyright Western Australia Agriculture ' + new Date().getFullYear(),
          href: 'https://www.agric.wa.gov.au'
        },
        chart: {
          events: {
            load: function () {
              this.renderer.image('https://weather.agric.wa.gov.au/assets/images/chart_dpird_logo.jpg', 10, 10, 160, 46).add();

            }
          }
        }
      }
    },
  };

  constructor(private weatherChartService: WeatherChartService) { }

  ngOnInit() {

    this.weatherChartService.onChartFilterParametersSet.subscribe(
      params => {
        this.onChartFilterParametersSet(params);
      })
  }

  onChartFilterParametersSet(params) {
    this.params = params;
    this.chartWindData = [];
    let stationCode = params.station.code;
    let interval = params.interval;
    let limit = 1000;
    let offset = 0;
    this.station = params.station;

    // Date parameters to be sent UTC datetime.
    // split routine filtering milliseconds.
    let startDateTime =  moment(params.event.value[0]).format('YYYY-MM-DD HH:mm:ss');//params.event.value[0].toISOString().split('.')[0] + "Z";
    let endDateTime =   moment(params.event.value[1]).format('YYYY-MM-DD HH:mm:ss');//params.event.value[1].toISOString().split('.')[0] + "Z";

    this.weatherChartService.getStationWindData(stationCode, interval, startDateTime, endDateTime, limit, offset).subscribe((data) => {
      // Foreach record returned
      for(let probe in data[0].wind ){
        // Format data for series  

        this.chartWindData[probe] = { avg: [], max: [], height: data[0].wind[probe].height, title: this.station.name + ' : Windspeed at ' + data[0].wind[probe].height + 'm (' + params.interval[0].toUpperCase() + params.interval.toLowerCase().substring(1) + ')' };

        for (let index in data) {

          let momentDate = moment(data[index].dateFrom).local();
          var tz_offset = (new Date().getTimezoneOffset() * -1) * 60 * 1000;
          var unix_time = momentDate.toDate().getTime() + tz_offset;

          this.chartWindData[probe].avg.push(...Array(...Array([unix_time, data[index].wind[probe].avg.speed])));
          this.chartWindData[probe].max.push(...Array(...Array([unix_time, data[index].wind[probe].max.speed])));
        }
      }

      switch (interval) {
        case "HOURLY":
          this.tickInterval = HOUR_INTERVAL;
          break;
        case "MONTHLY":
          this.tickInterval = MONTHLY_INTERVAL;
          break;
        case "YEARLY":
          this.tickInterval = YEARLY_INTERVAL;
          break;  
        default: //DAILY
          this.tickInterval = DAY_INTERVAL;
          break;
      }
      
      this.setChartOptions(params);

    });

    this.isDataLoaded = true;
  }

  setChartOptions(params) {
    let height = JSON.parse(JSON.stringify(this.chartWindData[0].height));
    let avg = JSON.parse(JSON.stringify(this.chartWindData[0].avg));
    let max = JSON.parse(JSON.stringify(this.chartWindData[0].max));

    let subtitleDateMask = 'DD-MM-YYYY';
    if (params.interval === 'HOURLY') {
      subtitleDateMask = 'DD-MM-YYYY hh:mma';
    }

    var index = 1;

    this.chartOptions = <any> {
      title: {
        text: this.station.name + ' : Windspeed at ' + height + 'm (' + params.interval[0].toUpperCase() + params.interval.toLowerCase().substring(1) + ')'
      },
      subtitle: {
        text: moment(params.event.value[0]).format(subtitleDateMask) + ' - ' + moment(params.event.value[1]).format(subtitleDateMask)
      },
      xAxis: {
        tickInterval: this.tickInterval,
        type: 'datetime'
      },
      series: [
        {
          data: avg,
          "name": 'Avg',
          color: AVG_SERIES_COLOUR,
          marker: {
            fillColor: AVG_SERIES_COLOUR,
            lineColor: '#FFFFFF',
            lineWidth: 1
          }
        }, {
          data: max,
          "name": 'Max',
          color: MAX_SERIES_COLOUR,
          marker: {
            fillColor: MAX_SERIES_COLOUR,
            lineColor: '#FFFFFF',
            lineWidth: 1
          }
        }
      ],
      exporting: {
        buttons: {
          contextButton: {
            menuItems: this.getDynamicMenuItems()
          }
        }
      }
    }
  }

  getDynamicMenuItems() : Highcharts.ExportingMenuObject[] {
    const menuItems: Highcharts.ExportingMenuObject[] = [];

    // Create custom menu items based on available wind probe heights
    this.chartWindData.forEach((probe, index) => {
      menuItems.push({
        text: `View wind at ${probe.height}m`,
        onclick: () => {
          this.setChartData(index); // Adjusts chart data based on height
        }
      });
    });

    menuItems.push(
      'separator' as any,
      'viewFullscreen' as any,
      'printChart' as any,
      'separator' as any,
      'downloadPNG' as any,
      'downloadJPEG' as any,
      'downloadPDF' as any,
      'downloadSVG' as any,
      'viewFullscreen' as any
    );

    return menuItems;
  }

  //Changes which probe to display data from.
  // Called when a dropdown menu item is selected. 
  setChartData(index){
    //Copy the data into local params to avoid scuffy shallow copy shenanigans
    let height = JSON.parse(JSON.stringify(this.chartWindData[index].height));
    let avg = JSON.parse(JSON.stringify(this.chartWindData[index].avg));
    let max = JSON.parse(JSON.stringify(this.chartWindData[index].max));

    this.chartOptions = <any> {
      title: {
        text: this.chartWindData[index].title
      },
      series: [
        {
          data: avg,
          color: AVG_SERIES_COLOUR,
          marker: {
            fillColor: AVG_SERIES_COLOUR,
            lineColor: '#FFFFFF',
            lineWidth: 1
          }
        },{
          data: max,
          color: MAX_SERIES_COLOUR,
          marker: {
            fillColor: MAX_SERIES_COLOUR,
            lineColor: '#FFFFFF',
            lineWidth: 1
          }
        }
      ]
    }
  }

  chartCallback(chart) {
  }
}
