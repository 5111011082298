import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

import { Station } from './models/station.model';
import { StationLatest } from './models/station-latest.model';
import { StationRainFall, RainFallMetrics } from './models/station-rainfall.model';
import { StationAvailability } from './models/station-availability.model';

import { APIResponse } from './response/api.response';

import { map } from 'rxjs/operators';
//import 'rxjs/add/operator/mergeMap'; //https://stackoverflow.com/questions/38481764/rxjs-flatmap-missing

import { StationSummary } from './models/station-summary.model';

import { StationPeriod } from './models/station-period.model';
import { EvapoTranspiration } from './models/evapo-transpiration.model';
import { AggregatedMetrics } from './models/aggregated-metrics.model';
import { MinuteData } from './models/minute-data.model';

import { WindProbe } from './models/wind-probe.model';

import { StationNearby } from './models/station-nearby.model';
import { start } from 'repl';

/*
 * Available Endpoints in the WEATHER API
 */
const ENDPOINT_STATIONS = 'stations';
const ENDPOINT_STATION = 'stations/{stationCode}';
const ENDPOINT_STATION_SUMMARIES = 'stations/{stationCode}/summaries';
const ENDPOINT_STATION_DATA = 'stations/{stationCode}/data';
//const ENDPOINT_STATION_TIME_SERIES = 'stations/{stationCode}/time-series';
const ENDPOINT_DAILY_TIME_SERIES = 'stations/summaries/daily/timeseries';
const ENDPOINT_LATEST = 'stations/latest';
const ENDPOINT_NEARBY = 'stations/nearby';
const ENDPOINT_EVENTS = 'stations/events';
const ENDPOINT_RAINFALL = 'stations/rainfall';
const ENDPOINT_STATIONS_AVAILABILITY = 'stations/availability';


/*enum Interval {
  15Min = "15min",
  30Min = "30min",
  Hourly = "hourly",
  Daily = "daily",
  Monthly = "monthly",
  Yearly = "monthly",
}*/


@Injectable()
export class WeatherService {

  apiUrl = environment.APIURI;


  //weatherStations: Station[] = [];
  //weatherStationsLatest: StationLatest[] = [];

  constructor(
    private http: HttpClient
  ) { }


  /**
   * Gets the stations availability in terms of how much data has been used towards the summary totals
   * if there is no date missing 100 availability
   */
  getStationsAvailability() {
    return this.http.get<StationAvailability[]>(this.apiUrl + ENDPOINT_STATIONS_AVAILABILITY + '?select=stationCode,stationName,dateTime,availability&group=web&limit=0',)
        .pipe(map(res => {
        return (<any>res).collection.map(item => {
          return new StationAvailability(item);
        });
      }));
  }

  // Used <any> to suprress error of unknown property got the idea from
  // https://github.com/Microsoft/TypeScript/issues/9448 that casts as <any>
  // seems to work but making a note incase there is a better way in the future
  // maybe cast type the response to to a known response type that has the property.
  getStations(): Observable<Station[]> {
    return this.http.get<Station[]>(this.apiUrl + ENDPOINT_STATIONS + "?group=web&limit=500",)
        .pipe(map(res => {
        return (<any>res).collection.map(item => {
          return new Station(item);
        });
      }));
  }


  getStation(stationCode: string) {

    let urlEndpoint = ENDPOINT_STATION.replace("{stationCode}", stationCode);
    return this.http.get<any>(this.apiUrl + urlEndpoint)
      .pipe(map(res => {
        let item = res.data;
        let station = new Station(item);
        return station;
      }));
  }



  getStationsLatest(): Observable<any> {
   return this.getStationLatest();
  }

  getStationLatest(stationCode?: string): Observable<any> {
    let url = this.apiUrl + ENDPOINT_LATEST ;

    url = url + '?group=web&limit=0' + "" + (stationCode != undefined ? '&stationCode=' + stationCode : '')

    return this.http.get<Station[]>(url)
      .pipe(map(res => {
        return (<any>res).collection.map(item => {

          let stationLatest = new StationLatest(item);

          return stationLatest;

        });
      }));
  }


  
  getSummaries(limit: number, offset: number, stationCode: string, startDateTime: string, endDateTime, interval: string, sort: string = null, select: string = null ): Observable<any> {

    let startDateQueryString = "startDateTime";
    let endDateQueryString = "endDateTime";
    
    switch (interval.toLowerCase()) {
      case "minute":
        if (sort !== undefined && sort !== null) {
          sort = sort.replace("periodFrom","dateTime");
        }
        return this.getStationMinuteData(limit, offset, stationCode, startDateTime, endDateTime, sort, select);
        //break;
      case "hourly":
      break;
      case "daily":
        startDateQueryString = "startDate";
        endDateQueryString = "endDate";

        startDateTime = startDateTime.substr(0,10);
        endDateTime = endDateTime.substr(0,10);

      break;
      case "monthly":
        startDateQueryString = "startMonth";
        endDateQueryString = "endMonth";

        startDateTime = startDateTime.substr(0,7);
        endDateTime = endDateTime.substr(0,7);

      break;
      case "yearly":
        startDateQueryString = "startYear";
        endDateQueryString = "endYear";

        startDateTime = startDateTime.substr(0,4);
        endDateTime = endDateTime.substr(0,4);

      break;
      default:
      // Default is for Hourly date time
      break;
    }

    let Endpoint = ENDPOINT_STATION_SUMMARIES.replace("{stationCode}", stationCode.toUpperCase());
    let URIEndpoint = this.apiUrl + Endpoint + '/' + interval + '?' + startDateQueryString + '=' + startDateTime + '&' + endDateQueryString +'=' + endDateTime + '&offset=' + offset + '&limit=' + limit + '&includeClosed=true'

    if (sort !== null) {
      URIEndpoint += "&sort=" + sort;
    }



    return this.http.get<any>(URIEndpoint)
     // return this.http.get<any>('http://weather-tst.agric.wa.gov.au/summary.json')
      .pipe(map(res => {
         //issue empty collection meaning no data
        //should return empty data instead
        //apis/weather-v2/issues/263
        // workaround
        if (res.collection != undefined) { // returned an empty collection should be empty data object
          res.data = { summaries: [] };
        }

        if (res.data.summaries != undefined && res.data.summaries.length > 0) {

          res.data.summaries = <StationSummary>res.data.summaries.map(
            
            item => new StationSummary(item)
            
          );
        }

        return new APIResponse(res.metadata, res.data.summaries);

      }));

  }


  /**
   * Get the station near from the given lat and long coordinates.
   * @latitude Latitide coordinate
   * @longitude Longitude coordinate
   * @radius Only look for stations within this range, defaults to 100.
   * 
   */
  getStationsNearby(latitude: number, longitude: number, radius:number = 100) {
    return this.http.get<StationNearby[]>(this.apiUrl + ENDPOINT_NEARBY + "?radius=" + radius + "&latitude=" + latitude.toFixed(4) + '&longitude=' + longitude.toFixed(4) + '&group=web')
      .pipe(map(res => {
        return (<any>res).collection.map(item => {
          let stationNearby = new StationNearby(item);        
          return stationNearby;
        });
      }));
  }



  


getStationMinuteData(limit: number, offset: number, stationCode: string, startDateTime: string, endDateTime, sort: string = null, select: string = null): Observable<any> {

   let Endpoint = ENDPOINT_STATION_DATA.replace("{stationCode}", stationCode.toUpperCase());
   let URIEndpoint = this.apiUrl + Endpoint + '?startDateTime=' + startDateTime + '&endDateTime=' + endDateTime + '&offset=' + offset + '&limit=' + limit

    if (sort !== null) {
      URIEndpoint += "&sort=" + sort;
    }
   


       return this.http.get<any>(URIEndpoint)
     // return this.http.get<any>('http://weather-tst.agric.wa.gov.au/summary.json')
      .pipe(map(res => {

        if (res.collection != undefined && res.collection.length > 0) {

          res.collection = <MinuteData>res.collection.map(
            item => 
               new MinuteData(item)
          );
        }

        return new APIResponse(res.metadata, res.collection);

      }));
}


 


  // Helper function to use as time series only gave one point and not min , max , avg
  // so ehre we will use summary endpoint filtering with select
  getShorterStationSummary(stationCode: string, interval: string, startDateTime: string, endDateTime: string, limit: number, offset: number, property: string) {

    let URIEndpoint = this.apiUrl + ENDPOINT_STATION_SUMMARIES.replace("{stationCode}", stationCode);

    // Based on the interval the date parameters have change to be move specific for the summary being filtered.
    // defaults to the HOURLY version of paramters.

    let queryParamStartDateKey = "startDateTime";
    let queryParamEndDateKey = "endDateTime";

    switch (interval) {
      case "DAILY":
        queryParamStartDateKey = "startDate";
        queryParamEndDateKey = "endDate";

        // Strip the string to purely just the date
        startDateTime = startDateTime.substr(0, 10);
        endDateTime = endDateTime.substr(0, 10);

        break;
      case "MONTHLY":
        queryParamStartDateKey = "startMonth";
        queryParamEndDateKey = "endMonth";

        // Strip the string to purely just the year and month
        startDateTime = startDateTime.substr(0, 7);
        endDateTime = endDateTime.substr(0, 7);

        break;
      case "YEARLY":
        queryParamStartDateKey = "startYear";
        queryParamEndDateKey = "endYear";

        // Strip the string to purely just the year
        startDateTime = startDateTime.substr(0, 4);
        endDateTime = endDateTime.substr(0, 4);

        break;
    
    }


    URIEndpoint = URIEndpoint + '/' + interval.toLowerCase() + '?' + queryParamStartDateKey + '=' + startDateTime + '&' + queryParamEndDateKey + '=' + endDateTime + '&limit=' + limit + '&offset=' + offset;
    URIEndpoint = URIEndpoint + '&select=period,' + property;

    return this.http.get<any[]>(URIEndpoint)
      .pipe(map(res => {
        if ((<any>res).data.summaries != undefined) { // if a response is available
          return (<any>res).data.summaries.map(item => {

            //let summaryItem = this.convertToSummaryModel(item);
            return {
              dateFrom: item.period.from,
              dateTo: item.period.to,
              min: item[property].min,
              max: item[property].max,
              avg: item[property].avg,
            };

          });
        }
      }));
  }

  // Functions ended up being copy and paste with slight variation on select statement
  //refactor this after deadline passed :)
  // pretty much a copy of getShorterStationSummary so needs refactoring , data being returned filtered for different type
  getStationEvaporationData(stationCode: string, interval: string, startDateTime: string, endDateTime: string, limit: number, offset: number) {

    let URIEndpoint = this.apiUrl + ENDPOINT_STATION_SUMMARIES.replace("{stationCode}", stationCode);

    // Based on the interval the date parameters have change to be move specific for the summary being filtered.
    // defaults to the HOURLY version of paramters.

    let queryParamStartDateKey = "startDateTime";
    let queryParamEndDateKey = "endDateTime";

    switch (interval) {
      case "DAILY":
        queryParamStartDateKey = "startDate";
        queryParamEndDateKey = "endDate";

        // Strip the string to purely just the date
        startDateTime = startDateTime.substr(0, 10);
        endDateTime = endDateTime.substr(0, 10);

        break;
      case "MONTHLY":
        queryParamStartDateKey = "startMonth";
        queryParamEndDateKey = "endMonth";

        // Strip the string to purely just the year and month
        startDateTime = startDateTime.substr(0, 7);
        endDateTime = endDateTime.substr(0, 7);

        break;
      case "YEARLY":
        queryParamStartDateKey = "startYear";
        queryParamEndDateKey = "endYear";

        // Strip the string to purely just the year
        startDateTime = startDateTime.substr(0, 4);
        endDateTime = endDateTime.substr(0, 4);

        break;

    }


    URIEndpoint = URIEndpoint + '/' + interval.toLowerCase() + '?' + queryParamStartDateKey + '=' + startDateTime + '&' + queryParamEndDateKey + '=' + endDateTime + '&limit=' + limit + '&offset=' + offset;
    URIEndpoint = URIEndpoint + '&select=period,panEvaporation,evapotranspiration';

    return this.http.get<any[]>(URIEndpoint)
      .pipe(map(res => {
        if ((<any>res).data.summaries != undefined) { // if a response is available
          return (<any>res).data.summaries.map(item => {

            //let summaryItem = this.convertToSummaryModel(item);
            return {
              dateFrom: item.period.from,
              dateTo: item.period.to,
              panEvaporation: item.panEvaporation,
             // panEvaporation12AM: item.panEvaporation12AM, ONLY ON DAILY TO BE LOOKED AT MORE
              tallCrop: item.evapotranspiration.tallCrop,
              shortCrop: item.evapotranspiration.shortCrop,
            };

          });
        }
      }));
  }


  // pretty much a copy of getShorterStationSummary so needs refactoring , data being returned filtered for different type
  getStationSolarExposureData(stationCode: string, interval: string, startDateTime: string, endDateTime: string, limit: number, offset: number) {

    let URIEndpoint = this.apiUrl + ENDPOINT_STATION_SUMMARIES.replace("{stationCode}", stationCode);

    // Based on the interval the date parameters have change to be move specific for the summary being filtered.
    // defaults to the HOURLY version of paramters.

    let queryParamStartDateKey = "startDateTime";
    let queryParamEndDateKey = "endDateTime";

    switch (interval) {
      case "DAILY":
        queryParamStartDateKey = "startDate";
        queryParamEndDateKey = "endDate";

        // Strip the string to purely just the date
        startDateTime = startDateTime.substr(0, 10);
        endDateTime = endDateTime.substr(0, 10);

        break;
      case "MONTHLY":
        queryParamStartDateKey = "startMonth";
        queryParamEndDateKey = "endMonth";

        // Strip the string to purely just the year and month
        startDateTime = startDateTime.substr(0, 7);
        endDateTime = endDateTime.substr(0, 7);

        break;
      case "YEARLY":
        queryParamStartDateKey = "startYear";
        queryParamEndDateKey = "endYear";

        // Strip the string to purely just the year
        startDateTime = startDateTime.substr(0, 4);
        endDateTime = endDateTime.substr(0, 4);

        break;

    }


    URIEndpoint = URIEndpoint + '/' + interval.toLowerCase() + '?' + queryParamStartDateKey + '=' + startDateTime + '&' + queryParamEndDateKey + '=' + endDateTime + '&limit=' + limit + '&offset=' + offset;
    URIEndpoint = URIEndpoint + '&select=period,solarExposure';

    return this.http.get<any[]>(URIEndpoint)
      .pipe(map(res => {
        if ((<any>res).data.summaries != undefined) { // if a response is available
          return (<any>res).data.summaries.map(item => {

            //let summaryItem = this.convertToSummaryModel(item);
            return {
              dateFrom: item.period.from,
              dateTo: item.period.to,
              solarExposure: item.solarExposure
            };

          });
        }
      }));
  }


  // pretty much a copy of getShorterStationSummary so needs refactoring , data being returned filtered for different type
  getStationWindData(stationCode: string, interval: string, startDateTime: string, endDateTime: string, limit: number, offset: number) {

    let URIEndpoint = this.apiUrl + ENDPOINT_STATION_SUMMARIES.replace("{stationCode}", stationCode);

    // Based on the interval the date parameters have change to be move specific for the summary being filtered.
    // defaults to the HOURLY version of paramters.

    let queryParamStartDateKey = "startDateTime";
    let queryParamEndDateKey = "endDateTime";

    switch (interval) {
      case "DAILY":
        queryParamStartDateKey = "startDate";
        queryParamEndDateKey = "endDate";

        // Strip the string to purely just the date
        startDateTime = startDateTime.substr(0, 10);
        endDateTime = endDateTime.substr(0, 10);

        break;
      case "MONTHLY":
        queryParamStartDateKey = "startMonth";
        queryParamEndDateKey = "endMonth";

        // Strip the string to purely just the year and month
        startDateTime = startDateTime.substr(0, 7);
        endDateTime = endDateTime.substr(0, 7);

        break;
      case "YEARLY":
        queryParamStartDateKey = "startYear";
        queryParamEndDateKey = "endYear";

        // Strip the string to purely just the year
        startDateTime = startDateTime.substr(0, 4);
        endDateTime = endDateTime.substr(0, 4);

        break;

    }


    URIEndpoint = URIEndpoint + '/' + interval.toLowerCase() + '?' + queryParamStartDateKey + '=' + startDateTime + '&' + queryParamEndDateKey + '=' + endDateTime + '&limit=' + limit + '&offset=' + offset;
    URIEndpoint = URIEndpoint + '&select=period,wind';

    return this.http.get<any[]>(URIEndpoint)
      .pipe(map(res => {
        if ((<any>res).data.summaries != undefined) { // if a response is available
          return (<any>res).data.summaries.map(item => {

            //let summaryItem = this.convertToSummaryModel(item);
            return {
              dateFrom: item.period.from,
              dateTo: item.period.to,
              //wind: item.wind[0] //only interested in the first wind height
              wind: item.wind
            };

          });
        }
      }));
  }



//quick hack
getStationWindRoseMinuteData(limit: number, offset: number, stationCode: string, startDateTime: string, endDateTime): Observable<any> {

   let Endpoint = ENDPOINT_STATION_DATA.replace("{stationCode}", stationCode.toUpperCase());
   let URIEndpoint = this.apiUrl + Endpoint + '?startDateTime=' + startDateTime + '&endDateTime=' + endDateTime + '&offset=' + offset + '&limit=' + limit
 
       return this.http.get<any[]>(URIEndpoint)
      .pipe(map(res => {
        if ((<any>res).collection != undefined) { // if a response is available
          return (<any>res).collection.map(item => {

            //let summaryItem = this.convertToSummaryModel(item);

            return {
              dateFrom: item.dateTime,
              dateTo: item.dateTime,
              //wind: item.wind[0] //only interested in the first wind height
              wind: item.wind
            };

          });
        }
      }));
}



  // pretty much a copy of getShorterStationSummary so needs refactoring , data being returned filtered for different type
  getStationDeltaTData(stationCode: string, interval: string, startDateTime: string, endDateTime: string, limit: number, offset: number) {

    let URIEndpoint = this.apiUrl + ENDPOINT_STATION_SUMMARIES.replace("{stationCode}", stationCode);

    // Based on the interval the date parameters have change to be move specific for the summary being filtered.
    // defaults to the HOURLY version of paramters.

    let queryParamStartDateKey = "startDateTime";
    let queryParamEndDateKey = "endDateTime";

    switch (interval) {
      case "DAILY":
        queryParamStartDateKey = "startDate";
        queryParamEndDateKey = "endDate";

        // Strip the string to purely just the date
        startDateTime = startDateTime.substr(0, 10);
        endDateTime = endDateTime.substr(0, 10);

        break;
      case "MONTHLY":
        queryParamStartDateKey = "startMonth";
        queryParamEndDateKey = "endMonth";

        // Strip the string to purely just the year and month
        startDateTime = startDateTime.substr(0, 7);
        endDateTime = endDateTime.substr(0, 7);

        break;
      case "YEARLY":
        queryParamStartDateKey = "startYear";
        queryParamEndDateKey = "endYear";

        // Strip the string to purely just the year
        startDateTime = startDateTime.substr(0, 4);
        endDateTime = endDateTime.substr(0, 4);

        break;

    }


    URIEndpoint = URIEndpoint + '/' + interval.toLowerCase() + '?' + queryParamStartDateKey + '=' + startDateTime + '&' + queryParamEndDateKey + '=' + endDateTime + '&limit=' + limit + '&offset=' + offset;
    URIEndpoint = URIEndpoint + '&select=period,deltaT,dewPoint';

    return this.http.get<any[]>(URIEndpoint)
      .pipe(map(res => {
        if ((<any>res).data.summaries != undefined) { // if a response is available
          return (<any>res).data.summaries.map(item => {

            //let summaryItem = this.convertToSummaryModel(item);
            return {
              dateFrom: item.period.from,
              dateTo: item.period.to,
              deltaT: item.deltaT,
              dewPoint: item.dewPoint
            };

          });
        }
      }));
  }

   // pretty much a copy of getShorterStationSummary so needs refactoring , data being returned filtered for different type
  // used for charting
  getStationRainFallData(stationCode: string, interval: string, startDateTime: string, endDateTime: string, limit: number, offset: number) {

    let URIEndpoint = this.apiUrl + ENDPOINT_STATION_SUMMARIES.replace("{stationCode}", stationCode);

    // Based on the interval the date parameters have change to be move specific for the summary being filtered.
    // defaults to the HOURLY version of paramters.

    let queryParamStartDateKey = "startDateTime";
    let queryParamEndDateKey = "endDateTime";

    switch (interval) {
      case "DAILY":
        queryParamStartDateKey = "startDate";
        queryParamEndDateKey = "endDate";

        // Strip the string to purely just the date
        startDateTime = startDateTime.substr(0, 10);
        endDateTime = endDateTime.substr(0, 10);

        break;
      case "MONTHLY":
        queryParamStartDateKey = "startMonth";
        queryParamEndDateKey = "endMonth";

        // Strip the string to purely just the year and month
        startDateTime = startDateTime.substr(0, 7);
        endDateTime = endDateTime.substr(0, 7);

        break;
      case "YEARLY":
        queryParamStartDateKey = "startYear";
        queryParamEndDateKey = "endYear";

        // Strip the string to purely just the year
        startDateTime = startDateTime.substr(0, 4);
        endDateTime = endDateTime.substr(0, 4);

        break;

    }


    URIEndpoint = URIEndpoint + '/' + interval.toLowerCase() + '?' + queryParamStartDateKey + '=' + startDateTime + '&' + queryParamEndDateKey + '=' + endDateTime + '&limit=' + limit + '&offset=' + offset;
    URIEndpoint = URIEndpoint + '&select=period,rainfall';

    return this.http.get<any[]>(URIEndpoint)
      .pipe(map(res => {
        if ((<any>res).data.summaries != undefined) { // if a response is available
          return (<any>res).data.summaries.map(item => {

            //let summaryItem = this.convertToSummaryModel(item);
            return {
              dateFrom: item.period.from,
              dateTo: item.period.to,
              rainfall: item.rainfall
            };

          });
        }
      }));
  }

  getStationRainfall(startDateTime: string, endDateTime: string, stationCode: string = null) {

    let URI = this.apiUrl + ENDPOINT_RAINFALL + "?limit=900&group=web";

    if (startDateTime !== "" && endDateTime !== "") {
      URI = URI + "&startDate=" + startDateTime + "&endDate=" + endDateTime;
    }

    if (stationCode !== null) {
      URI = URI + "&stationCode=" + stationCode;
    }


    return this.http.get<StationRainFall[]>(URI)
      .pipe(map(res => {
        return (<any>res).collection.map(item => {

          let stationRainFall = new StationRainFall(item);

          return stationRainFall;
        });
      }));
  }


  /**
   * Get data for a specific metric based on a date range
   * @param stationCode The unique station code for the weather station
   * @param interval The interval for the data to be returned.
   * @param metric The property to be queried, e.g airTempreture
   * @param startDateTime The start date time.
   * @param endDateTime The end date time.
   */
  /*getStationTimeSeries(stationCode: string, interval: string, metric: string, startDateTime: string, endDateTime: string) {


    let urlEndpoint = ENDPOINT_STATION_TIME_SERIES.replace("{stationCode}", stationCode);
    urlEndpoint = urlEndpoint + '/' + interval + '?property=' + metric + '&startDateTime=' + startDateTime + '&endDateTime' + endDateTime;  
    //monthly?property=airTemperature&startDateTime=2018-05-05T00%3A00%3A00Z&endDateTime=2018-05-05T23%3A00%3A00Z

    return this.http.get<any[]>(urlEndpoint)
      .map(res => {
        return (<any>res).collection;
      });

  }*/

  getTimeSeries(startDate: string, endDate: string, imetric: string) {

    let urlEndpoint = ENDPOINT_DAILY_TIME_SERIES + '?includeClosed=false&endDate=' + endDate + '&startDate=' + startDate + '&limit=0&offset=0&select=stationCode,stationName,' + imetric ;

    return this.http.get<any[]>(this.apiUrl + urlEndpoint)
      .pipe(map(res => {
        return (<any>res);
      }));
  }


  getExtremeEventByStationCode(stationCode: string, eventMetric: string, startDateTime: string, endDateTime: string, operator: string , threshold: number) {
    let urlEndpoint = ENDPOINT_EVENTS + '?stationCode=' + stationCode + '&operator=' + operator + '&threshold=' + threshold +'&property=' + eventMetric + '&startDateTime=' + startDateTime +'&endDateTime=' + endDateTime + '&interval=hourly&select=extremeValue';
    return this.http.get<any[]>(this.apiUrl + urlEndpoint)
      .pipe(map(res => {
        return (<any>res);
      }));
  }


  /**
   * Mapping function to convert a response into the model which can be used on various endpoints
   * to save repetition
   */
  convertToSummaryModel(item: any) {

    return new StationSummary(item);
  }

}
