import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { UserSettingsModel } from '../../models/user-settings.model';
import { AppConstants } from '../../app.constants';

import { of, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  public userSettings: UserSettingsModel = null;

  constructor(
    private cookieService: CookieService,
    private constants: AppConstants
    ) { }


  /**
   * Get the user settings model that is currently stored in the USER_SETTINGS
   * cookie.
   * @retuns UserSettingsModel Model of the currently save settings
   */
  getSettings(): UserSettingsModel {

    if (this.userSettings == null) {
      this.userSettings = new UserSettingsModel(
        this.cookieService.get(this.constants.USER_SETTINGS) === undefined ? null : this.cookieService.get(this.constants.USER_SETTINGS)
        );
    }
    
    return this.userSettings;
  }

  /**
   * Save the current settigns into a cookie.
   * @param userSettings Model of the settings to be saved
   */
  saveSettings(userSettings: UserSettingsModel ) {
    const cookieDate = new Date();
    cookieDate.setTime(cookieDate.getTime() + 31540000000); // Year Plenty :)
    this.cookieService.put(this.constants.USER_SETTINGS, userSettings.toJson(),  { expires: cookieDate.toUTCString(), secure: true } );
  }

}
