

import { Component, ViewEncapsulation, ViewChild, ElementRef, PipeTransform, Pipe, OnInit, OnDestroy, Renderer2, HostListener, CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";
import { environment } from 'environments/environment';
import { GoogleAnalyticsService } from './services/google-analytics/google-analytics.service';

import { StateService } from './services/state/state.service';
import { LoggingService } from './services/logging/logging.service';
import { SettingsService } from './services/settings/settings.service';
import { UserSettingsModel, KeyValuePair, StationGroupSetting } from './models/user-settings.model';
import { AppConstants } from './app.constants';

import { StatusService } from './services/status/status.service';

import {Router, NavigationEnd} from "@angular/router";

import { DeviceDetectorService } from 'ngx-device-detector';

import { CookieService, CookieOptions } from 'ngx-cookie';

import { Subscription } from "rxjs";

// Idle and KeepAlive
import {Idle, DEFAULT_INTERRUPTSOURCES} from '@ng-idle/core';
import {Keepalive} from '@ng-idle/keepalive';

//connection service
import { ConnectionService } from 'ng-connection-service';

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {

  static readonly SESSION_TIMEOUT_SECONDS = 3600;

  @HostListener('window:beforeunload', [ '$event' ])
  beforeUnloadHander(event) {
    // Save the curremt settings
    this.saveStateSettings();
  }

  //timeout
  
  public idleState = 'Not started.';
  public timedOut = false;
  public lastPing?: Date = null;
  //timeout

  public title = 'Weather';
  public currentUrl: string ;
  public deviceInfo: any;
  public theme: any;
  public isDataLoaded: boolean = false;
  public ApplicationReadySubscription: Subscription;
  public themeSubscription: Subscription;
  public settings;
  public userSettings: UserSettingsModel;

   unloadApplication(event) {
    //unloadApplication
    return "application unloading";
    //alert('closing');
  }

  constructor( 
    private stateService: StateService,
    private googleAnalyticsService: GoogleAnalyticsService,

    private deviceService: DeviceDetectorService,
    private loggingService: LoggingService,
    private router: Router,
    private renderer: Renderer2,
    private cookieService: CookieService,
    private constants: AppConstants,
    private idle: Idle,
    private keepalive: Keepalive,
    private connectionService: ConnectionService,
    private settingsService: SettingsService,
    private statusService: StatusService) {

    // Connection service
    this.connectionService.monitor().subscribe(isConnected => {
      this.stateService.connectionInfo.isConnected = isConnected;
      if (isConnected) {
        this.stateService.connectionInfo.status = 'ONLINE';
      } else {
        this.stateService.connectionInfo.status = 'OFFLINE';
      }
    });

    statusService.getWeatherSystemStatus().subscribe( (message) =>{
      if(message != undefined){
        this.stateService.openApplicationModal({Title: 'Service Interuption', Message: message, Colour: 'orange' });
      }
    });

  
    this.appendGaTrackingCode();

    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd ) {
        //this.currentUrl = event.urlAfterRedirects.replace("/","") + '-page';
        var uriSections = event.urlAfterRedirects.substr(1).split('/');
        if (uriSections[0] === '' || uriSections[0].length < 3) { // check length also had one user with /#/map as the url in favourites eeek
          this.currentUrl = 'map-page';
        } else {
          this.currentUrl = uriSections[0] + '-page';
        }

        this.stateService.stateSettings.currentPage = this.currentUrl;
        this.deviceInfo = this.deviceService.getDeviceInfo();

        if (this.deviceInfo !== undefined) {
          const browser = this.deviceInfo.browser !== undefined && this.deviceInfo.browser !== null ? this.deviceInfo.browser : '';
          const version = this.deviceInfo.browser_version !== undefined && this.deviceInfo.browser_version !== null ? this.deviceInfo.browser_version.replace(/\./g,'-') : '';
          this.currentUrl += ' ' + browser + ' version_' + version;
        }
     }
    });

    this.settings = this.stateService;

    this.themeSubscription = this.stateService.getThemeChangeMessage().subscribe( data => {
       this.theme = data;
       this.stateService.stateSettings.theme = data;
       this.setTheme();
    });

    this.ApplicationReadySubscription = this.stateService.onApplicationReady().subscribe( data => {
      this.isDataLoaded = true;
    })

    this.addConsoleMessage();

    // Load the User Settings
    this.userSettings = this.settingsService.getSettings();
    this.stateService.stateSettings.isHeaderVisible = this.userSettings.hideAppHeader ? false : true  ;
  
    // Additional settings when the applicaiton has been closed
    this.settings.mapSettings.weatherVariablesPanelIsOpen = this.userSettings.hideMetricsPanel === true ? false : true;
    this.settings.mapSettings.menuIsOpen = this.userSettings.hideMapControlsMenu === true ? false : true;

    this.stateService.mapSettings.radarOptions[0].isChecked = this.userSettings.isPerthRadarChecked;      // Perth
    this.stateService.mapSettings.radarOptions[1].isChecked = this.userSettings.isNewdegateChecked;       // Newdegate
    this.stateService.mapSettings.radarOptions[2].isChecked = this.userSettings.isKalgoorlieChecked;      // Kalgoorlie
    this.stateService.mapSettings.radarOptions[3].isChecked = this.userSettings.isSouthDoodlakineChecked; // South Doodlakine
    this.stateService.mapSettings.radarOptions[4].isChecked = this.userSettings.isWatherooChecked;        // Watheroo
    this.stateService.mapSettings.radarOptions[5].isChecked = this.userSettings.isAlbanyChecked;          // Albany
    this.stateService.mapSettings.radarOptions[6].isChecked = this.userSettings.isGeraldtonChecked;       // Geraldton
    this.stateService.mapSettings.radarOptions[7].isChecked = this.userSettings.isEsperanceChecked;       // Esperance

    this.settings.mapSettings.currentMapLayer = this.isEmpty(this.userSettings.mapDefaultLayer) ? "OPEN_LAYERS" : this.userSettings.mapDefaultLayer.key;
    this.stateService.stateSettings.isHeaderVisible = this.userSettings.hideAppHeader ? false : true;
    this.theme = this.userSettings.enableDarkMode ? "dark-gotham" : '';
    this.stateService.stateSettings.disableVirtualStations = this.userSettings.disableVirtualStations;

    // Default AirTemp
    const defaultMetric = new KeyValuePair();
    defaultMetric.key = 'AirTemperature';
    defaultMetric.value = 'Temperature °C';

    this.stateService.mapSettings.metricSelected = this.isEmpty(this.userSettings.metricChosen) ? defaultMetric : this.userSettings.metricChosen;
    this.stateService.stateSettings.theme = this.theme;
    this.stateService.mapSettings.showMarkerLabels = this.userSettings.hideMetricsValueLabels ? false : true;

    // this.stateService.mapSettings.defaultRegion = this.userSettings.mapDefaultRegion.value; // value stored and key used to filter out on map page the region array
    this.setTheme();


    // sets an idle timeout of 5 seconds, for testing purposes.
    idle.setIdle(AppComponent.SESSION_TIMEOUT_SECONDS);
    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    idle.setTimeout(5);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => { 
      this.idleState = 'No longer idle.';
      this.loggingService.logToConsole(this.idleState);
    });

    idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
      this.loggingService.logToConsole(this.idleState);
    });

    idle.onIdleStart.subscribe(
      () => {
        this.idleState = 'You\'ve gone idle!';
        this.loggingService.logToConsole(this.idleState);
      }
    );

    idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'You will time out in ' + countdown + ' seconds!';
      this.loggingService.logToConsole(this.idleState);
    });

    // sets the ping interval to 15 seconds
    keepalive.interval(AppComponent.SESSION_TIMEOUT_SECONDS);

    keepalive.onPing.subscribe(() => this.lastPing = new Date());

    this.reset();


  }


  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
    this.loggingService.logToConsole(this.idleState);
  }

 isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
  }

   ngOnDestroy() {
     if ( this.theme !== '') {
       this.renderer.removeClass(document.body, this.theme);
     }

     // unsubscribe
     this.ApplicationReadySubscription.unsubscribe();
     this.themeSubscription.unsubscribe();

   }


   /**
    * Append the Google tracking code into the application.
    * 
    */
  private appendGaTrackingCode() {
    document.addEventListener('DOMContentLoaded', () => {
     //this.addGtmScriptTagToHead(environment.GoogleTrackingManagerId);
     //this.addGtmNoscriptTagToBody(environment.GoogleTrackingManagerId);
     this.addGtmGtagToHead(environment.GoogleTagTrackingId);
    });
  }

  addGtmGtagToHead(googleAnalyticsId: string): void {
    // Create the script element
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`;
  
    // Append the script element to the document head
    const head = document.getElementsByTagName('head')[0];
    head.appendChild(script);
  
    const scriptTag = document.createElement('script');
    // Call the gtag function to configure Google Analytics
    scriptTag.innerHTML = `
      window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${googleAnalyticsId}');
        `;
    
    head.appendChild(scriptTag);

  }

  /**
   * Google Tag Manager (GTM) function to add the script portion of the GTM
   * into the HEAD of the HTML document.
   * @param id GTM-IDENTIFIER
   */
  addGtmScriptTagToHead(id: string): void {
    const scriptTag = document.createElement('script');
    scriptTag.innerHTML = `
      (function(w,d,s,l,i){
        w[l]=w[l]||[];
        w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
        var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
        j.async=true;
        j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
        f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${id}');
    `;
    document.head.appendChild(scriptTag);
  }

  /**
   * Google Tag Manager (GTM) function to add the NOSCRIPT version into the body
   * of the HTML document.
   * @param id GTM-IDENTIFIER
   */
  addGtmNoscriptTagToBody(id: string): void {
    const noscriptTag = document.createElement('noscript');
    noscriptTag.innerHTML = `
      <iframe
        src="https://www.googletagmanager.com/ns.html?id=${id}"
        height="0"
        width="0"
        style="display: none; visibility: hidden">
      </iframe>
    `;
    document.body.appendChild(noscriptTag);
  }
  


  /** Globally change the look of the application by updating the class appended to
   *  the body class of the applicationCache.
   */
  setTheme() {
    if ( this.theme !== '') {
      this.renderer.removeClass(document.body, this.theme);
      this.renderer.addClass(document.body, this.theme);
    } else {
      this.renderer.removeClass(document.body, 'dark-gotham');
    }
  }

  /**
   * Message that will appear in the console for that curious developer or user that likes to check
   * behind the scenes of the inner magic of the application
   */
  addConsoleMessage() {
    this.loggingService.logToConsole('Welcome behind the scenes, if you find any issues or have feature requests, please use our feedback email so we can improve the application.', true);
    this.loggingService.logToConsole('email: econnectedGrainBelt@dpird.wa.gov.au', true);
    this.loggingService.logToConsole('Thank you', true);
  }

  saveStateSettings() {
    this.userSettings.hideAppHeader = this.stateService.stateSettings.isHeaderVisible ? false : true ;
    this.userSettings.hideMetricsValueLabels = this.stateService.mapSettings.showMarkerLabels === true ? false : true;

    // Map Layers
    this.userSettings.setDefaultLayer(this.stateService.mapSettings.currentMapLayer);
    this.userSettings.setMetricOptionDefault(this.stateService.mapSettings.metricSelected.key);

    //currently radars stored in Order in array but needs to change
    this.userSettings.isPerthRadarChecked = this.settings.mapSettings.radarOptions[0].isChecked;      // Perth
    this.userSettings.isNewdegateChecked = this.settings.mapSettings.radarOptions[1].isChecked;       // Newdegate
    this.userSettings.isKalgoorlieChecked = this.settings.mapSettings.radarOptions[2].isChecked;      // Kalgoorlie
    this.userSettings.isSouthDoodlakineChecked = this.settings.mapSettings.radarOptions[3].isChecked; // South Doodlakine
    this.userSettings.isWatherooChecked = this.settings.mapSettings.radarOptions[4].isChecked;        // Watheroo
    this.userSettings.isAlbanyChecked = this.settings.mapSettings.radarOptions[5].isChecked;          // Albany
    this.userSettings.isGeraldtonChecked = this.settings.mapSettings.radarOptions[6].isChecked;       // Geraldton
    this.userSettings.isEsperanceChecked = this.settings.mapSettings.radarOptions[7].isChecked;       // Esperance
    this.userSettings.enableDarkMode = this.theme === '' ? false : true;
    this.userSettings.disableVirtualStations = this.stateService.stateSettings.disableVirtualStations;


    // Save the Weather Stations Layer Settings
    let StationsArray = [];
    for (let i = 0; i < this.settings.mapSettings.layers.weatherStations.length; i++) {
      let stationItem = new StationGroupSetting();
      stationItem.GroupId = this.settings.mapSettings.layers.weatherStations[i].groupId;
      stationItem.isClustered = this.settings.mapSettings.layers.weatherStations[i].isClustered;
      stationItem.isVisible = this.settings.mapSettings.layers.weatherStations[i].isStationsVisible;
      StationsArray.push(stationItem);
    };

    this.userSettings.stationLayers = Object.assign({}, StationsArray);

    this.settingsService.saveSettings(this.userSettings);
  }

}
