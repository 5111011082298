import { StationLatest } from '../services/weather/models/station-latest.model';
import { memo } from '../shared/memo';

import { WindProbeModel } from './wind-probe.model'
import * as moment from 'moment';


export class StationLatestModel extends StationLatest {
 
  public WindProbeAvgSpeed: number;
  public hasWindProbe: boolean;

  public WindProbeAvgDirectionCompassPoint: string;



  // Additional Fields for #533
  // which will be obtained from events API on the selection of
  // a station.
  public MaxTempSince9am: number;
  public MaxTempPast24HrsTo9am: number;


  public getLastUpdatedMinutes() {
    
    //let lastUpdateDate = new Date(this.DateTimeUTC.replace('+00','Z').replace(' ', 'T'));
    let lastUpdateDate = new Date(this.DateTimeUTC);
    //console.log(lastUpdateDate);
    let currentDateTime = new Date();
   // let diff = Math.abs(lastUpdateDate.getTime() - currentDateTime.getTime());
    let diff = currentDateTime.getTime() - lastUpdateDate.getTime() ;
    var minutes = Math.floor((diff / 1000) / 60);
    return minutes;
  }

  public getLastUpdatedNoticeDuration() {
    var mins = this.getLastUpdatedMinutes();
    
    if (mins > 1440) {
      let days = (mins / 60 ) / 24;
      return days.toFixed(0) + " days ago";
    }

    if (mins > 180) {
      let hours = mins / 60 ;
      return hours.toFixed(0) + " hours ago";
    }

    if (mins == 0){
      return "now";
    }

    return mins.toString() + " minutes ago";
  }

  constructor(stationLatest?: StationLatest) {
    super();
    this.hasWindProbe = false; //default false
    if ( stationLatest !== undefined && stationLatest !== null) {

      this.Code = stationLatest.Code;
      this.Name = stationLatest.Name;
      this.DateTimeUTC = stationLatest.DateTimeUTC;
      this.AirTemperature = stationLatest.AirTemperature;
      this.ApparentTemperature = stationLatest.ApparentTemperature;
      this.BarometricPressure = stationLatest.BarometricPressure;
      this.RelativeHumidity = stationLatest.RelativeHumidity;
      this.SoilTemperature = stationLatest.SoilTemperature;
      this.DewPoint = stationLatest.DewPoint;
      this.DeltaT = stationLatest.DeltaT;
      this.WetBulb = stationLatest.WetBulb;
      this.SprayingCondition = stationLatest.SprayingCondition; // spraying condition
      this.SolarExposure = stationLatest.SolarExposure;
      this.SolarIrradiance = stationLatest.SolarIrradiance;
      this.EtoShortCrop = stationLatest.EtoShortCrop;
      this.EtoShortCropTo3PM = stationLatest.EtoShortCropTo3PM;
      this.EtoTallCrop = stationLatest.EtoTallCrop;
      this.EtoTallCropTo3PM = stationLatest.EtoTallCropTo3PM;
      this.RainFallSince9AM = stationLatest.RainFallSince9AM;
      this.RainFallTo3PM = stationLatest.RainFallTo3PM;
      this.PanEvaporation = stationLatest.PanEvaporation;
      this.PanEvaporation12AM = stationLatest.PanEvaporation12AM;
      this.PanEvaporationTo3PM = stationLatest.PanEvaporationTo3PM;
      this.BatteryVoltage = stationLatest.BatteryVoltage;
      this.ErrorsToday = stationLatest.ErrorsToday;
      this.ErrorsLast7Days = stationLatest.ErrorsLast7Days;
      this.WindProbes = stationLatest.WindProbes;

      if (this.WindProbes[0] !== undefined && this.WindProbes[0].AvgSpeed !== undefined && this.WindProbes[0].AvgDirectionCompassPoint !== undefined) {
        this.WindProbeAvgSpeed = this.WindProbes[0].AvgSpeed;
        this.hasWindProbe = true; //windprobe available
        this.WindProbeAvgDirectionCompassPoint = this.WindProbes[0].AvgDirectionCompassPoint
        this.SprayingConditionWindOnly = this.getSprayingConditionWordingWind()
      }

      this.AirTemperatureMaxLast24Hrs = stationLatest.AirTemperatureMaxLast24Hrs;
      this.AirTemperatureMinLast24Hrs = stationLatest.AirTemperatureMinLast24Hrs;

      this.AirTemperatureHigh6AMto9PM = stationLatest.AirTemperatureHigh6AMto9PM;
      this.AirTemperatureLow6PMto9AM = stationLatest.AirTemperatureLow6PMto9AM;

      this.SprayingConditionWithWind = this.getSprayingConditionWithWind();
      this.SprayingCondition = this.getSprayingConditionWordingDeltaT();  //update wording - different from API Calculation
      

    }
  }



  getLastUpdatedDateTime(formatMask) {
    const date = moment(this.DateTimeUTC).format(formatMask);

    return date;
  }


  getSprayingConditionWordingDeltaT() {

    let sprayingCondition = 'Unsuitable';

    switch (true) {
      case (this.DeltaT < 2): {
        sprayingCondition = 'Marginal';
      break;
      }
      case (this.DeltaT >= 2 && this.DeltaT <= 8): {
        sprayingCondition = 'Preferred';
      break;
      }
      case (this.DeltaT > 8 && this.DeltaT <= 10): {
        sprayingCondition = 'Marginal';
      break;
      }
      case (this.DeltaT > 10): {
        sprayingCondition = 'Unsuitable';
      break;
      }
    }

    return sprayingCondition;
  }


  getSprayingConditionWordingWind() {
    
    let sprayingCondition = 'Unsuitable';
    let WindSpeed = this.WindProbeAvgSpeed;

    if ((WindSpeed > 15 || WindSpeed < 3)) {
      sprayingCondition = "Unsuitable";
    } else if ((WindSpeed >= 3  && WindSpeed < 5)) {
      sprayingCondition = "Marginal";
    } else if ((WindSpeed >= 5 && WindSpeed <= 15)) {
      sprayingCondition = "Preferred";
    } 

    return sprayingCondition;
    
  }

  getSprayingConditionColour() {

    let styleColour = '';

    switch (true) {
      case (this.DeltaT < 2): {
      styleColour = 'orange';
      break;
      }
      case (this.DeltaT >= 2 && this.DeltaT <= 8): {
      styleColour = 'green';
      break;
      }
      case (this.DeltaT > 8 && this.DeltaT <= 10): {
      styleColour = 'orange';
      break;
      }
      case (this.DeltaT > 10): {
      styleColour = 'red';
      break;
      }
    }

    return styleColour;
  }


  getSprayingConditionWithWind() {
    
    var deltaT = this.DeltaT;
    var WindSpeed = this.WindProbeAvgSpeed;
    
    if (this.getLastUpdatedMinutes() > 45) {
      return "Check Last Updated";
    }

    if ((deltaT > 10) && (WindSpeed > 15 || WindSpeed < 3)) {
      return "Unsuitable";
    } else if ((deltaT > 8 || deltaT < 2) && (WindSpeed >= 3 && WindSpeed < 5)) {
      return "Marginal";
    } else if ((deltaT >= 2 && deltaT <= 8) && (WindSpeed >= 5 && WindSpeed <= 15)) {
      return "Preferred";
    } else {
      return "Unsuitable";
    }
  }


  getSprayingConditionColour2 = memo(() => {
    var deltaT = this.DeltaT;
    var WindSpeed = this.WindProbeAvgSpeed;
 
    if ((deltaT > 10) && (WindSpeed > 15 || WindSpeed < 3)) {
      return "red";
    } else if ((deltaT > 8 || deltaT < 2) && (WindSpeed >= 3  && WindSpeed < 5)) {
      return "orange";
    } else if ((deltaT >= 2 && deltaT <= 8) && (WindSpeed >= 5 && WindSpeed <= 15)) {
      return "green";
    } else {
      return "red";
    }
    }
  );
  

  getSprayingConditionColourWind = memo(() => {

    var WindSpeed = this.WindProbeAvgSpeed;

    if ((WindSpeed > 15 || WindSpeed < 3)) {
      return "red";
    } else if ((WindSpeed >= 3 && WindSpeed < 5)) {
      return "orange";
    } else if ((WindSpeed >= 5 && WindSpeed <= 15)) {
      return "green";
    } else {
      return "grey";
    }
    }
  );




}
