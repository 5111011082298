import { GlobalErrorHandler } from './global-error-handler';
import { BrowserModule } from '@angular/platform-browser';

//https://scotch.io/tutorials/angular-2-transclusion-using-ng-content

import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler } from '@angular/core'; //https://stackoverflow.com/questions/39428132/custom-elements-schema-added-to-ngmodule-schemas-still-showing-error
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';

//https://github.com/scttcper/ngx-toastr/
//import { ToastrModule } from 'ngx-toastr';

import { Ng5SliderModule } from 'ng5-slider';

//https://www.npmjs.com/package/ngx-device-detector
import { DeviceDetectorModule } from 'ngx-device-detector';

// Fancy select 
// https://cuppalabs.github.io/components/multiselectDropdown/
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';


import { WindDirectionComponent } from './components/wind-direction/wind-direction.component';

// High Charts
// https://github.com/highcharts/highcharts-angular
// include high charts the wrapper isnt properly done yet so you have to make some adjustments to the tsconfig files
// https://github.com/highcharts/highcharts-angular/issues/1
// just make this to the main config not the one in teh app directory as otherwise it will complain that the file cannot be found
import { HighchartsChartModule } from 'highcharts-angular';


import { AngularDraggableModule } from 'angular2-draggable';

// Drag and Drop functionailty
// https://github.com/cedvdb/ng2draggable
//import { Draggable } from 'ng2draggable/draggable.directive';


// https://github.com/DanielYKPan/date-time-picker
import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE} from '@danielmoncada/angular-datetime-picker';
import { OwlMomentDateTimeModule } from '@danielmoncada/angular-datetime-picker-moment-adapter';


// https://tutorialedge.net/typescript/angular/angular-cookies-tutorial/
// https://github.com/salemdar/angular2-cookie/issues/37
import { CookieModule, CookieService } from 'ngx-cookie'; //CookieOptions

//https://github.com/cipchk/ngx-countdown
//Used to give indication of page refresh
import { CountdownModule } from 'ngx-countdown';
import { TplFlipComponent } from './components/flip-component/flip.component';

// https://hackedbychinese.github.io/ng2-idle/
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'; // this includes the core NgIdleModule but includes keepalive providers for easy wireup

// Datatable
//import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { TooltipModule } from 'primeng/tooltip';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MultiSelectModule } from 'primeng/multiselect';
import { SelectButtonModule } from 'primeng/selectbutton';
import { PaginatorModule } from 'primeng/paginator';

// Services
import { StateService } from './services/state/state.service';
import { LoggingService } from './services/logging/logging.service';
import { WeatherService } from './services/weather/weather.service';
import { GisToWebService } from './services/gis-to-web/gis-to-web.service';
import { GoogleAnalyticsService } from './services/google-analytics/google-analytics.service';

import { WeatherChartService } from './services/weather/weather.chart.service';
import { RadarService } from './services/radar/radar.service';
import { ExportService } from './services/export-service/export-service';
import { DataCache } from './services/data-cache/data-cache.service';
import { SettingsService } from './services/settings/settings.service';
import { ForecastingService } from './services/forecasting/forecasting.service';


// Moment library for dates
//https://github.com/urish/ngx-moment
import { MomentModule } from 'ngx-moment';

//Http communication
import { HttpClientModule } from '@angular/common/http'; 

//Leaflet Map asymmetrik
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { LeafletMarkerClusterModule } from '@asymmetrik/ngx-leaflet-markercluster';

//Leaflet Fullscreen Module
import {NgxLeafletFullscreenModule} from '@runette/ngx-leaflet-fullscreen'


//NG boostrap for the directives
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';


import { AppComponent, SafePipe } from './app.component';
import { PrimaryHeaderComponent } from './primary-header/primary-header.component';
import { AboutComponent } from './about/about.component';

import { MapComponent } from './map/map.component';

// when we want to add to the HTTP_INTERCEPTORS Array.
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APIKeyInterceptor } from './apikey.interceptor';


import { StationSidebarComponent } from './station-sidebar/station-sidebar.component';
import { RdIconButtonComponent } from './shared/rd-icon-button/rd-icon-button.component';
import { StationTableComponent } from './station-table/station-table.component';
import { StationDashboardComponent } from './station-dashboard/station-dashboard.component';

import { EvapotranspirationComponent } from './station-dashboard/evapotranspiration/evapotranspiration.component';

import { LeftSidebarMenuComponent } from './left-sidebar-menu/left-sidebar-menu.component';
import { UserPageComponent } from './user-page/user-page.component';
import { NotificationPageComponent } from './notification-page/notification-page.component';

import { ChartsComponent } from './station-dashboard/charts/charts.component';
import { DatePickerComponent } from './shared/date-picker/date-picker.component';

import { DeveloperApiPageComponent } from './developer-api-page/developer-api-page.component';
import { TermsConditionsPageComponent } from './terms-conditions-page/terms-conditions-page.component';
import { MobileFilterMenuComponent } from './shared/mobile-filter-menu/mobile-filter-menu.component';

import { TemperatureChartComponent } from './station-dashboard/charts/temperature-chart/temperature-chart.component';
import { MobileMapControlsComponent } from './shared/mobile-map-controls/mobile-map-controls.component';
import { RelativeHumidityChartComponent } from './station-dashboard/charts/relative-humidity-chart/relative-humidity-chart.component';
import { EvaporationChartComponent } from './station-dashboard/charts/evaporation-chart/evaporation-chart.component';
import { SolarExposureChartComponent } from './station-dashboard/charts/solar-exposure-chart/solar-exposure-chart.component';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { WindRoseChartComponent } from './station-dashboard/charts/wind-rose-chart/wind-rose-chart.component';
import { DeltaTchartComponent } from './station-dashboard/charts/delta-tchart/delta-tchart.component';
import { WindSpeedChartComponent } from './station-dashboard/charts/wind-speed-chart/wind-speed-chart.component';
import { RainFallChartComponent } from './station-dashboard/charts/rain-fall-chart/rain-fall-chart.component';
import { MobileWeatherDataBtnsComponent } from './shared/mobile-weather-data-btns/mobile-weather-data-btns.component';
import { CorporateHeaderComponent } from './shared/corporate-header/corporate-header.component';
import { ModalComponent } from './modal/modal.component';
import { SummaryDataTableComponent } from './station-dashboard/summary-data-table/summary-data-table.component';
import { ChangeLogComponent } from './change-log/change-log.component';
import { MapWeatherVariablesLegendComponent } from './map/map-weather-variables-legend/map-weather-variables-legend.component';
import { ChartComponent } from './station-dashboard/chart/chart.component';
import { ChartWindroseComponent } from './station-dashboard/chart-windrose/chart-windrose.component';

import { AppConstants } from './app.constants';
import { StationListComponent } from './station-list/station-list.component';


import 'simplebar';
import { SummaryMetricTableComponent } from './summary-metric-table/summary-metric-table.component';
import { RadarForecastComponent } from './station-dashboard/charts/radar-forecast/radar-forecast.component';
import { WeatherVariablesComponent } from './map/map-bottom-navbar/metric-select/metric-select.component';
import { RadarSelectComponent } from './map/map-bottom-navbar/radar-select/radar-select.component';
import { StationSelectComponent } from './map/map-bottom-navbar/station-select/station-select.component';
import { MapLayerSelectComponent } from './map/map-bottom-navbar/map-layer-select/map-layer-select.component';
import { MapBottomNavbarComponent } from './map/map-bottom-navbar/map-bottom-navbar.component';
import { MoreSelectComponent } from './map/map-bottom-navbar/more-select/more-select.component';
import { VideoTipsPageComponent } from './video-tips-page/video-tips-page.component'; // simplebar library to have nice looking scrollbars
import { StatusService } from './services/status/status.service';



const routes: Routes = [
  { path: '', pathMatch: 'full',  component: MapComponent },
  { path: 'station-list', component: StationListComponent },
  { path: 'map', component: MapComponent },
  { path: 'about', component: AboutComponent },
  { path: 'change-log', component: ChangeLogComponent },
  { path: 'user', component: UserPageComponent },
  { path: 'notifications', component: NotificationPageComponent },
  { path: 'station/:stationCode', component: StationDashboardComponent, pathMatch: 'full' },
  { path: 'station/:stationCode/:summaryType', component: StationDashboardComponent, pathMatch: 'full' },
  { path: 'developer-api', component: DeveloperApiPageComponent },
  { path: 'terms-and-conditions', component: TermsConditionsPageComponent },
  { path: 'disclaimer', component: DisclaimerComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'video-tips', component: VideoTipsPageComponent },
  { path: '**', redirectTo: 'map' }
];


@NgModule({
  schemas: [
    NO_ERRORS_SCHEMA,
    CUSTOM_ELEMENTS_SCHEMA
  ],
  declarations: [
    AppComponent,
    SafePipe,
    PrimaryHeaderComponent,
    AboutComponent,
    MapComponent,
    StationSidebarComponent,
    RdIconButtonComponent,
    StationTableComponent,
    StationDashboardComponent,
    EvapotranspirationComponent,
    LeftSidebarMenuComponent,
    UserPageComponent,
    NotificationPageComponent,
    ChartsComponent,
    DatePickerComponent,
    DeveloperApiPageComponent,
    TermsConditionsPageComponent,
    MobileFilterMenuComponent,
    TemperatureChartComponent,
    MobileMapControlsComponent,
    RelativeHumidityChartComponent,
    EvaporationChartComponent,
    SolarExposureChartComponent,
    DisclaimerComponent,
    PrivacyPolicyComponent,
    WindRoseChartComponent,
    DeltaTchartComponent,
    WindSpeedChartComponent,
    RainFallChartComponent,
    MobileWeatherDataBtnsComponent,
    CorporateHeaderComponent,
    ModalComponent,
    SummaryDataTableComponent,
    ChangeLogComponent,
    MapWeatherVariablesLegendComponent,
    ChartComponent,
    ChartWindroseComponent,
    StationListComponent,
    TplFlipComponent,
    SummaryMetricTableComponent,
    WeatherVariablesComponent,
    RadarSelectComponent,
    StationSelectComponent,
    MapLayerSelectComponent,
    MapBottomNavbarComponent,
    MoreSelectComponent,
    RadarForecastComponent,
    VideoTipsPageComponent,
    WindDirectionComponent
  ],
  imports: [
    BrowserModule,
    CookieModule.forRoot(), //added extra after updating to ngx-cookie ( CookieOptions error)
    LeafletModule,
    LeafletMarkerClusterModule.forRoot(),
    NgxLeafletFullscreenModule,
    NgbModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    MomentModule,
    TableModule,
    DropdownModule,
    MultiSelectModule,
    TooltipModule,
    SelectButtonModule,
    PaginatorModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    OwlMomentDateTimeModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
    DeviceDetectorModule.forRoot(),
    CountdownModule,
    AngularMultiSelectModule,
  //  ToastrModule.forRoot(),
    Ng5SliderModule,
    NgIdleKeepaliveModule.forRoot(),
    HighchartsChartModule,
    AngularDraggableModule,
    MatTooltipModule
  ],
  exports: [ RouterModule ],
  providers: [
    CookieService,
   // { provide: CookieOptions, useValue: false }, //https://github.com/salemdar/angular2-cookie/issues/39
    {provide: OWL_DATE_TIME_LOCALE, useValue: 'en-AU'},
    StateService,
    SettingsService,
    LoggingService,
    WeatherService,
    GisToWebService,
    DataCache,
    StatusService,
    WeatherChartService,
    GoogleAnalyticsService,
    ExportService,
    RadarService,
    ForecastingService,
    AppConstants,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: APIKeyInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
