<div class="MetricBoxContainer" *ngIf="isDataLoaded; else evap_na">
    <div class="MetricBoxHeader">
        
        <div style="display:flex; height:0;">
            <div class="MetricBoxTitle">Evapotranspiration</div>
            <div class="icon icon-info-icon leftside-icon" matTooltip="ETo to 3pm is only available after 3pm each day" matTooltipPosition="above"></div>
        </div>
        

        <div class="dropdown-hamburger" (click)="toggleDropdown()">
            <span class="icon icon-hamburger-icon custom-hamburger"></span>
        </div>

        <div *ngIf="dropdownVisible"  class="custom-dropdown">
            <div class="custom-dropdown-list">
                <div class="custom-dropdown-item" *ngFor="let eto of etoModel; let i = index" (click)="setEtoDisplay(i)">{{etoModel[i].label}}</div>
            </div>
        </div>
    </div>

    <div class="MetricBoxBody">
        <div class="MetricBoxBodyInner">

            <div *ngIf="etoModel[etoSelect].etoShort != null; else etoShort_na"> 
                <div class="MetricBoxValue">{{etoModel[etoSelect].etoShort}}
                    <div class="MetricBoxDesc"><span class="top">Eto short</span>
                        <span class="bottom">mm</span></div>
                </div>
                <div class="MetricBoxSubText">{{etoModel[etoSelect].label}}</div>
            </div>
            <ng-template #etoShort_na>
                <div class="MetricBoxValue">
                    N/A
                </div>
            </ng-template>

        </div>
    </div>
    <div class="MetricBoxFooter">
        <div *ngIf="etoModel[etoSelect].etoTall != null" class="left">
            ETo tall<br>
            {{etoModel[etoSelect].etoTall}}mm
        </div>
        <div *ngIf="etoModel[etoSelect].panEvap != null" class="right">
            Pan evap.<br>
            {{etoModel[etoSelect].panEvap}}mm
        </div>
    </div>
</div>

<!-- When no Evapotranspiration -->
<ng-template #evap_na>
    <div class="MetricBoxContainer">
        <div class="MetricBoxHeader">
            <div class="MetricBoxTitle">Evapotranspiration</div>
            <div class="MetricBoxInfo">
                <!-- <div class="icon icon-info-icon"></div>-->
            </div>
        </div>

        <div class="MetricBoxBody">
            <div class="MetricBoxBodyInner">

                <div class="MetricBoxValue">
                    <div class="MetricBoxDesc"><span class="top"></span><span
                            class="bottom"></span></div>
                </div>
                <div class="MetricBoxSubText">Not available for this station</div>

            </div>
        </div>
        <div class="MetricBoxFooter">
            <div class="left"></div>
            <div class="right"></div>

        </div>
    </div>
</ng-template>